import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import ImageComponent from '@sharedComponent/image-component';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import EDragger from '@pages/DesignSystem/Dragger';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { images } from 'src/assets/img';
import { SITEMAP } from 'src/routes/sitemap';
import EdenredTypo from '@sharedComponent/typography';
import TradeLicensePopup from './TradeLicensePopup';
import useCheckNewEstablishmentId from '@pages/employees/manage-employees/hooks/useCheckNewEstablishmentId';
import { useLocation } from 'react-router-dom';
import Toast from '@pages/DesignSystem/Toast';
import { manageEmployeesActions } from '../../action';

const TradeLicense = () => {
	const history = useHistory();
	const [showPopup, setShowPopup] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [allFiles, setAllFiles] = useState({});
	const [loading, setLoading] = useState(false);
	const { establishmentEmployees, callEstablishmentId, clearEstablishmentId, redirectPage, uploadTradeLicense, profileUpdateStatus } =
		useCheckNewEstablishmentId();
	const location = useLocation();
	const dispatch = useDispatch();
	const isLocationUpdate = location.state?.action === 'update'; // profile-update
	const isLocationUpdateBulk = location.state?.action === 'update-bulk';
	const isLocationAdd = location.state?.action === 'add'; // individual-employee
	const isLocationAddBulk = location.state?.action === 'add-bulk';

	useEffect(() => {
		callEstablishmentId(false);
	}, []);

	useEffect(() => {
		if (establishmentEmployees?.status?.isSuccessful) {
			nextStepsOnEmployees();
		}
		if (establishmentEmployees?.status && !establishmentEmployees?.status?.isSuccessful) {
			setLoading(false);
		}
	}, [establishmentEmployees]);

	const handleNextSteps = async () => {
		if (establishmentEmployees?.nextSteps) {
			await establishmentEmployees.nextSteps();
		}
	};

	const nextStepsOnEmployees = async () => {
		if (isLocationUpdate || isLocationUpdateBulk) {
			await handleNextSteps();
			setShowPopup(true);
			setLoading(false);
		} else if (isLocationAdd || isLocationAddBulk) {
			await handleNextSteps();
			clearEstablishmentId();
			redirectPage(SITEMAP.employees.employeeNextSteps, true);
			setLoading(false);
		} else {
			await handleNextSteps();
			clearEstablishmentId();
			redirectPage(SITEMAP.employees.manage);
			setLoading(false);
		}
	};

	async function onNextClick() {
		setLoading(true);
		const formData = new FormData();
		// appending all files and establishment id to the form data
		Object.entries(allFiles).map(([id, file], index) => {
			formData.append(`tradeLicenseUploadRequest[${index}].File`, file);
			formData.append(`tradeLicenseUploadRequest[${index}].EstablishmentId`, id);
		});
		uploadTradeLicense(formData);
	}
	const employeeEstIdCount = establishmentEmployees?.establishmentIds ?? [];

	const changePageOnUpdate = () => {
		if (isLocationUpdateBulk || profileUpdateStatus?.success) {
			setShowToast(true);
		}
		// the reason of adding timeout is to show the toast message before redirecting to the next page
		setTimeout(() => {
			clearEstablishmentId();
			if (isLocationUpdateBulk) {
				redirectPage(SITEMAP.employees.manage);
			} else {
				redirectPage(SITEMAP.employees.view);
			}
			dispatch(manageEmployeesActions.creators.clearProfileUpdate());
		}, 500);
	};

	const goBack = () => {
		if (isLocationAddBulk || isLocationUpdateBulk) {
			history.push({ pathname: SITEMAP.employees.bulkEmployeesView, state: { ...location.state } });
		} else if (isLocationUpdate) {
			history.push({ pathname: SITEMAP.employees.view, state: { ...location.state } });
		} else if (isLocationAdd) {
			history.push({ pathname: SITEMAP.employees.addInvididualDocument, state: { ...location.state } });
		} else {
			history.push(SITEMAP.employees.manage);
		}
	};

	const nextButtonDisabled = () => {
		if (employeeEstIdCount.length) {
			return !(Object.keys(allFiles).length === employeeEstIdCount.length);
		}
		return true;
	};

	return (
		<>
			{showToast && (
				<Toast visible={showToast} clearPopUp={() => setShowToast(false)} color="#8EF9B3" left="40%">
					The Employees have been updated
				</Toast>
			)}
			{showPopup && <TradeLicensePopup onClick={changePageOnUpdate} />}
			<Col className="upload-speadsheet-col-content">
				<Row className="upload_employees_spreadsheet_container">
					<Col className="upload_employees_spreadsheet_title">
						<div className="add-employee-header">
							<ETypo h2 bold color="#00344E">
								New Establishment ID Found For {employeeEstIdCount.length > 1 ? 'These' : 'This'} Employee
							</ETypo>
						</div>
						<div className="add-employee-button-container">
							<EdenredButtons style={{ padding: '8px 16px 8px 10px', width: '85px' }} btnType={'secondary'} onClick={goBack}>
								<ImageComponent src={images.leftsarrow}></ImageComponent>{' '}
								<ETypo style={{ marginLeft: '3px' }} b1 bold color="#484B52">
									Back
								</ETypo>
							</EdenredButtons>
							<EdenredButtons
								style={{ marginLeft: '8px', width: '65px' }}
								onClick={onNextClick}
								loading={loading}
								disabled={nextButtonDisabled()}>
								Next
							</EdenredButtons>
						</div>
					</Col>
					<Col style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
						{employeeEstIdCount?.map((id) =>
							id ? <UploadPdf allFiles={allFiles} setAllFiles={setAllFiles} id={id} establishmentId={id} /> : null
						)}
					</Col>
				</Row>
			</Col>
		</>
	);
};

export default TradeLicense;

const UploadPdf = ({ allFiles = [], setAllFiles, id = 1234, establishmentId = null }) => {
	const [uploaded, setUploaded] = useState(false);

	const uploadConfig = (info) => {
		setAllFiles((prev) => ({ ...prev, [id]: info }));
		setUploaded(true);
	};
	const draggerIcon = () => {
		if (uploaded) {
			return focus_state_icons.NewDesignCheck;
		} else {
			return focus_state_icons.hrUpload;
		}
	};
	const draggerStatus = () => {
		if (uploaded) {
			return 'done';
		} else {
			return 'new';
		}
	};

	return (
		<Col className="upload-box-and-table-container">
			<ETypo h4>
				Upload Trade License for Establishment ID:{' '}
				<ETypo h4 color="#4991B6">
					{establishmentId}
				</ETypo>
			</ETypo>
			<br />
			<ETypo b1 light>
				To proceed with adding employees under this establishment, please upload the trade license associated with the new Establishment ID
				for verification.
			</ETypo>
			<div className="add-employee-dragger-container">
				<EDragger
					{...{
						idleIcon: draggerIcon(),
						draggerStatus: draggerStatus(),
						uploadDeleteIcon: focus_state_icons.NewDelete,
						acceptedTypes: '.pdf',
						onChange: uploadConfig,
						onRemoveUpload: () => {
							setUploaded(false);
							const files = { ...allFiles };
							delete files[id];
							setAllFiles(files);
						},
					}}
				/>
			</div>
			<Col xl={24} lg={24} md={24} sm={20} xs={20} className="upload_delete_employees_spreadsheet_template_hint">
				<EdenredTypo b3>
					Submit the trade license for establishment ID "{establishmentId}" for verification to add employees from this trade license
				</EdenredTypo>
			</Col>
		</Col>
	);
};

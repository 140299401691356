import React, { useEffect, useRef, useState } from 'react';
import { Col, Input, Row, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContentLoader from '@sharedComponent/content-loader';
import EdenredButton from '@sharedComponent/button';
import EdenredEmployeesCard from '@sharedComponent/card/employees/employees-card';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import { ExcelDownload } from '@sharedComponent/excel/global-excel';
import ImageComponent from '@sharedComponent/image-component';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { default as TableV2 } from '@pages/DesignSystem/Table/Table';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import ETypo from '@pages/DesignSystem/Typo';
import { debounceHandler } from '@helpers';
import { icons } from 'src/assets/icons';
import ClevertapReact from 'src/utils/clever-tap';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import useOutsideClick from './useOutsideClick';
import useClaimsBasedContents from '../../hooks/useClaimsBasedContents/useClaimsBasedContents';
import { UnemploymentInsuranceShortBanner } from '@pages/payroll/run-payroll/components/unemployment-insurance-short-banner';
import ManageEmployeeHeader from './employees/ManageEmployeeHeader';
import { MANAGE_EMPLOYEES_CONSTANTS } from '../constants';
import FilterButton from '@pages/DesignSystem/filter';
import { SITEMAP } from 'src/routes/sitemap';

const { TabPane } = Tabs;

const { activeAccounts, creatingAccounts, deletedAccounts, needAttention } = MANAGE_EMPLOYEES_CONSTANTS.tabsContents;

const ManageEmployeePresentational = (props) => {
	let {
		columns,
		dataSource,
		selectedEmployees,
		checkbox,
		handleCheckBox,
		totalEmpSelect,
		handlepassdata1,
		employetableSelect,
		employetableNotSelect,
		employeeCount,
		isDataLoading,
		filterData,
		selectedEmployee,
		selectedTab,
		enableFooter,
		activeAccountPagination,
		creatingAccountPagination,
		needAttentionPagination,
		deletedAccountPagination,
		handlePaginationChange,
		totalCount,
		employeeListForDownload,
		onSearch,
		buttonRef,
		activeTabs,
		isSubcribed,
		getEmployeeDetailsForDownload,
		setDataCountToShow,
		dataCountToShow,
		handleFilterChange,
		handleClearAllFilter,
	} = props;
	const history = useHistory();
	const [filter, setFilter] = useState(false);
	const [visible, setVisible] = useState(false);
	const [size, setSize] = useState(0);
	const ref = useRef();
	let zoom = window.innerHeight - 64;

	const [popupNew, setPopupNew] = useState(false);
	let navbarClaims = useSelector((state) => state?.sharedStates?.userNavigation);
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);
	const { shouldRender: renderBasedOnClaims } = useClaimsBasedContents();

	useEffect(() => {
		if (!isOnlyEmployeeExits && navbarClaims && (!navbarClaims?.tourGuide || !navbarClaims?.tourGuide?.includes('employee')) && isSubcribed) {
			setPopupNew(true);
		} else {
			setPopupNew(false);
		}
	}, [navbarClaims]);

	useOutsideClick(ref, () => {
		if (filter) setFilter(false);
	});

	let [searchedText, setSearchedText] = useState('');

	const tableLoading = {
		spinning: isDataLoading,
		indicator: <ContentLoader />,
	};

	let columnKeys = [
		{ labelKey: 'Name', valueKey: 'employeeName' },
		{ labelKey: 'Employee ID', valueKey: 'employeeCode' },
		{ labelKey: 'Account Number', valueKey: 'accountNumber' },
		{ labelKey: 'WPS Establishment ID', valueKey: 'establishmentId' },
		{ labelKey: 'Emirates ID Number', valueKey: 'emiratesId' },
		{ labelKey: 'Bank Routing Code', valueKey: 'bankRoutingCode' },
		{ labelKey: 'Card Created Date', valueKey: 'rhfreceivedDate' },
		{ labelKey: 'IBAN', valueKey: 'bankAccountNo' },
		{ labelKey: 'Bank Name', valueKey: 'bankName' },
		{ labelKey: 'WPS Person ID', valueKey: 'wpsPersonid' },
		{ labelKey: 'Passport Number', valueKey: 'passportNumber' },
	];
	if (activeTabs === deletedAccounts.key) {
		let columndata = [
			{
				labelKey: 'Card Delete Date',
				valueKey: 'deletedDate',
			},
		];
		columnKeys = [...columnKeys, ...columndata];
	}

	if (activeTabs === activeAccounts.key || activeTabs === deletedAccounts.key) {
		let columndata = [
			{
				labelKey: 'Account Type',
				valueKey: 'accountType',
			},
		];
		columnKeys = [...columnKeys, ...columndata];
	}

	const showModal = () => setVisible(true);

	const handleBulkDelete = () => {
		ClevertapReact.event('Delete_BulkEmployees');
		history.push('/manange/employees/bulk/delete');
	};

	let tableData = [...dataSource];
	if (searchedText) {
		tableData = tableData?.filter((data) => {
			return (
				(data && data?.employeeName && data?.employeeName?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0) ||
				(data && data?.employeeCode && data?.employeeCode?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0) ||
				(data && data?.wpsPersonid && data?.wpsPersonid?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0)
			);
		});
	}

	const handleUpdateBulk = () => history.push({ pathname: SITEMAP.employees.updateBulk });
	const activeEmployeeCount = employeeCount?.activeEmployeeCount || 0;
	const creatingAccountNoCount = employeeCount?.creatingAccountNoCount || 0;
	const needAttentionCount = employeeCount?.needAttentionCount || 0;
	const deleteAccountCount = employeeCount?.deleteAccountCount || 0;

	const handleDowloademployee = () => ClevertapReact.event('Download_Employees');

	return (
		<>
			{/* To display banner for non-subscribed unemployment insurance corprates */}
			<UnemploymentInsuranceShortBanner />
			{/* End */}
			<div style={{ width: '100%', padding: '0px 40px 0px 40px', marginBottom: '40px' }} className="display_none_max_width_600px">
				<EdenredHeader AddUsers />
				{/* web */}
				<ManageEmployeeHeader
					renderBasedOnClaims={renderBasedOnClaims}
					handleBulkDelete={handleBulkDelete}
					handleUpdateBulk={handleUpdateBulk}
				/>
				<EmployeeTabs
					selectedTab={selectedTab}
					activeEmployeeCount={activeEmployeeCount}
					handleTabChange={props.handleTabChange}
					creatingAccountNoCount={creatingAccountNoCount}
					needAttentionCount={needAttentionCount}
					deleteAccountCount={deleteAccountCount}
				/>
				<EmployeeTable
					selectedTab={selectedTab}
					creatingAccountPagination={creatingAccountPagination}
					needAttentionPagination={needAttentionPagination}
					deletedAccountPagination={deletedAccountPagination}
					activeAccountPagination={activeAccountPagination}
					columns={columns}
					dataSource={dataSource}
					totalCount={totalCount}
					handlePaginationChange={handlePaginationChange}
					onSearch={onSearch}
					employeeListForDownload={employeeListForDownload}
					getEmployeeDetailsForDownload={getEmployeeDetailsForDownload}
					handleDowloademployee={handleDowloademployee}
					tableLoading={tableLoading?.spinning}
					setDataCountToShow={setDataCountToShow}
					dataCountToShow={dataCountToShow}
					columnKeys={columnKeys}
					activeTabs={activeTabs}
					buttonRef={buttonRef}
					renderBasedOnClaims={renderBasedOnClaims}
					filterData={filterData}
					handleFilterChange={handleFilterChange}
					handleClearAllFilter={handleClearAllFilter}
				/>
			</div>
			<Row className="manage-employee-mobile-view">
				<Col xs={24} sm={24} className="manage-employee-mobile-header">
					<EdenredTypo>Employees</EdenredTypo>
				</Col>
				<Col xs={24} sm={24} className="manage-employee-mobile-filtersearch">
					<Row className="manage-employee-mobile-filtersearchcol">
						<Col xl={3} lg={6} md={7} xs={8} sm={8} className="mobile-filter">
							<Link
								to={{
									pathname: '/employees/manage_employee/filter',
									state: { filterData },
								}}>
								<Row className="manage-employee-mobile-filter">
									<ImageComponent src={icons.not_focus.Filter} />
									Filter
								</Row>
							</Link>
						</Col>
						<Col xl={10} lg={15} md={10} xs={16} sm={16}>
							<Input
								className="emp-search"
								prefix={<ImageComponent src={icons.not_focus.Search} />}
								placeholder="Search by Name or Employee ID."
								onInput={(e) => debounceHandler(e.target.value, onSearch)}
							/>
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={24} className="manage-employee-mobile-tab">
					<Tabs onChange={props.handleTabChange}>
						<TabPane
							tab={
								<span>
									{activeAccounts.text}({activeEmployeeCount || 0})
								</span>
							}
							key="1">
							<Col xs={24} sm={24} className="select">
								{totalEmpSelect > 0 ? <EdenredTypo>Selected {totalEmpSelect}</EdenredTypo> : null}
								<Col xs={24} sm={24}>
									{employetableSelect?.map((tab, ind) => (
										<>
											<EdenredEmployeesCard
												type="ManageEmployeeTableCard"
												content={tab}
												style={{ boxShadow: tab.isSelected === true ? '0px 10px 20px rgba(174, 182, 183, 0.5)' : null }}
												ind={ind}
												className="card_content"
												handleCheckBox={handleCheckBox}
												handlepassdata={() => handlepassdata1('', tab)}
												selected={selectedEmployee}
											/>
										</>
									))}
								</Col>
								<EdenredTypo> Not Selected </EdenredTypo>
								<Col xs={24} sm={24}>
									<InfiniteScroll
										dataLength={totalEmpSelect}
										style={{ padding: '0 8px 20px 8px' }}
										next={(pagination) => handlePaginationChange(pagination + 1, 'activeAccountPagination')}
										hasMore={true}
										height={`${zoom}px`}
										endMessage={
											<p style={{ textAlign: 'center' }}>
												<b>Yay! You have seen it all</b>
											</p>
										}>
										{employetableNotSelect?.map((tab, ind) => (
											<EdenredEmployeesCard
												selected={selectedEmployee}
												type="ManageEmployeeTableCard"
												content={tab}
												style={{ boxShadow: tab.isSelected === true ? '0px 10px 20px rgba(174, 182, 183, 0.5)' : null }}
												ind={ind}
												className="card_content"
												handleCheckBox={handleCheckBox}
												handlepassdata={() => handlepassdata1('', tab)}
											/>
										))}
									</InfiniteScroll>
								</Col>
							</Col>
						</TabPane>
						<TabPane
							tab={
								<span>
									{needAttention.text}({employeeCount?.needAttentionCount || 0})
								</span>
							}
							key="3">
							<Col xs={24} sm={24} className="select">
								{totalEmpSelect > 0 ? <EdenredTypo>Selected {totalEmpSelect}</EdenredTypo> : <EdenredTypo> Not Selected</EdenredTypo>}
							</Col>
							<Col xs={24} sm={24}>
								<InfiniteScroll
									dataLength={dataSource.length}
									style={{ padding: '0 8px 20px 8px' }}
									next={(pagination) => handlePaginationChange(pagination + 1, 'needAttentionPagination')}
									hasMore={true}
									height={`${zoom}px`}
									endMessage={
										<p style={{ textAlign: 'center' }}>
											<b>Yay! You have seen it all</b>
										</p>
									}>
									{dataSource?.map((tab, ind) => (
										<EdenredEmployeesCard
											type="ManageEmployeeTableCard"
											content={tab}
											className="card_content"
											checkbox={checkbox}
											handleCheckBox={handleCheckBox}
											ind={ind}
											selected={selectedEmployee}
											handlepassdata={() => handlepassdata1('', tab)}
										/>
									))}
								</InfiniteScroll>
							</Col>
						</TabPane>
						<TabPane
							tab={
								<span>
									{creatingAccounts.text}({employeeCount?.creatingAccountNoCount || 0})
								</span>
							}
							key="2">
							<Col xs={24} sm={24} className="select">
								{totalEmpSelect > 0 ? <EdenredTypo>Selected {totalEmpSelect}</EdenredTypo> : <EdenredTypo> Not Selected</EdenredTypo>}
							</Col>
							<Col xs={24} sm={24}>
								<InfiniteScroll
									dataLength={totalEmpSelect}
									style={{ padding: '0 8px 20px 8px' }}
									next={(pagination) => handlePaginationChange(pagination + 1, 'creatingAccountPagination')}
									hasMore={true}
									height={`${zoom}px`}
									endMessage={
										<p style={{ textAlign: 'center' }}>
											<b>Yay! You have seen it all</b>
										</p>
									}>
									{employetableNotSelect?.map((tab, ind) => (
										<EdenredEmployeesCard
											type="ManageEmployeeTableCard"
											content={tab}
											className="card_content"
											ind={ind}
											selected={selectedEmployee}
											handleCheckBox={handleCheckBox}
											handlepassdata={() => handlepassdata1('', tab)}
										/>
									))}
								</InfiniteScroll>
							</Col>
						</TabPane>
						<TabPane
							tab={
								<span>
									{deletedAccounts.text}({employeeCount?.deleteAccountCount || 0})
								</span>
							}
							key="4">
							<Col xs={24} sm={24} className="select">
								{totalEmpSelect > 0 ? <EdenredTypo>Selected {totalEmpSelect}</EdenredTypo> : <EdenredTypo> Not Selected</EdenredTypo>}
							</Col>
							<Col xs={24} sm={24}>
								<InfiniteScroll
									dataLength={totalEmpSelect}
									style={{ padding: '0 8px 20px 8px' }}
									next={(pagination) => handlePaginationChange(pagination + 1, 'deletedAccountPagination')}
									hasMore={true}
									height={`${zoom}px`}
									endMessage={
										<p style={{ textAlign: 'center' }}>
											<b>Yay! You have seen it all</b>
										</p>
									}>
									{dataSource?.map((tab, ind) => (
										<EdenredEmployeesCard
											type="ManageEmployeeTableCard"
											content={tab}
											className="card_content"
											checkbox={checkbox}
											handleCheckBox={handleCheckBox}
											ind={ind}
											selected={selectedEmployee}
											handlepassdata={() => handlepassdata1('', tab)}
										/>
									))}
								</InfiniteScroll>
							</Col>
						</TabPane>
					</Tabs>
				</Col>
				{enableFooter && (
					<>
						<Col xs={24} sm={24} className="manage-employee-mobile-add">
							<Link to={{ pathname: '/employees/add/multiple/employees/upload' }}>
								<EdenredButton>Add Employee</EdenredButton>
							</Link>
						</Col>
						<Col xs={24} sm={24} className="manage-employee-mobile-download">
							<ExcelDownload
								dataSet={selectedEmployees?.length > 0 ? selectedEmployees : dataSource ? dataSource : []}
								columnKeys={columnKeys}
								className="view-salary-history"
								filename={`employeelist`}
								activeTabs={activeTabs}
								element={<EdenredButton type="link">Download Employee Details</EdenredButton>}
							/>
						</Col>
					</>
				)}
				{enableFooter && [activeAccounts.key]?.indexOf(selectedTab) >= 0 && totalEmpSelect > 0 ? (
					<Row className="delete_show" onClick={showModal}>
						<Col xs={24} sm={24} className="delete_employees_button">
							<EdenredButton>Delete {totalEmpSelect} Employee(s)</EdenredButton>
						</Col>
					</Row>
				) : null}
			</Row>
		</>
	);
};

const EmployeeTabs = ({ selectedTab, activeEmployeeCount, handleTabChange, creatingAccountNoCount, needAttentionCount, deleteAccountCount }) => {
	return (
		<>
			<section className="tabs_section_wrapper">
				<div
					className={`tab first_child ${selectedTab === activeAccounts.key && 'active'} `}
					onClick={() => handleTabChange(activeAccounts.key)}>
					<span>{activeAccounts.text}</span>
					<span className={`badge ${selectedTab === activeAccounts.key && 'active'} `}>{activeEmployeeCount}</span>
				</div>
				<div className={`tab ${selectedTab === needAttention.key && 'active'} `} onClick={() => handleTabChange(needAttention.key)}>
					<span>{needAttention.text}</span>
					<span className={`badge ${selectedTab === needAttention.key && 'active'} `}>{needAttentionCount}</span>
				</div>
				<div className={`tab ${selectedTab === creatingAccounts.key && 'active'} `} onClick={() => handleTabChange(creatingAccounts.key)}>
					<span>{creatingAccounts.text}</span>
					<span className={`badge ${selectedTab === creatingAccounts.key && 'active'} `}>{creatingAccountNoCount}</span>
				</div>

				<div
					className={`tab last_child ${selectedTab === deletedAccounts.key && 'active'} `}
					onClick={() => handleTabChange(deletedAccounts.key)}>
					<span>{deletedAccounts.text}</span>
					<span className={`badge ${selectedTab === deletedAccounts.key && 'active'} `}>{deleteAccountCount}</span>
				</div>
			</section>
		</>
	);
};

const EmployeeTableHeader = ({
	onSearch,
	employeeListForDownload,
	getEmployeeDetailsForDownload,
	handleDowloademployee,
	setDataCountToShow,
	dataCountToShow,
	columnKeys,
	activeTabs,
	buttonRef,
	renderBasedOnClaims,
	selectedTab,
	filterData = [],
	handleFilterChange,
	handleClearAllFilter,
}) => {
	const [selectedFilters, setSelectedFilters] = useState([]);
	const selectedFilterLabel = useRef('');
	const { needAttention } = MANAGE_EMPLOYEES_CONSTANTS.tabsContents;

	// Need attention table filter
	const _filterData = [
		{
			filterLabel: 'Card Status',
			count: 0,
			filterValue:
				(filterData &&
					filterData?.needAttentionStatuses?.map((data) => ({
						filterValue: data.value,
						value: data.key,
					}))) ||
				[],
		},
	];

	const needAttentionTabClevertapEvent = (statusKey = '') => {
		switch (statusKey) {
			case 1:
				ClevertapReact.event(needAttention.cleverTapEvents.filters.blocked);
				break;
			case 2:
				ClevertapReact.event(needAttention.cleverTapEvents.filters.toBeBlocked);
				break;
			case 3:
				ClevertapReact.event(needAttention.cleverTapEvents.filters.deletes);
				break;
			default:
				break;
		}
	};

	const onFilterValueSelected = (data) => {
		// clever tap event
		needAttentionTabClevertapEvent(data.name); // passing a filter key

		// Keeping the selected filter in the result and adding in our selected filter which used for filter component value
		const result = [...selectedFilters];
		const indexOfSelectedValue = result.indexOf(data?.value);
		selectedFilterLabel.current = data.value;
		if (indexOfSelectedValue > -1) {
			result.splice(indexOfSelectedValue, 1);
		} else {
			result.push(data.value);
		}
		setSelectedFilters(result);
		// Need to update an filter data of which filter is selected
		const updateFilter = { ...filterData };
		updateFilter.needAttentionStatuses = updateFilter.needAttentionStatuses.map((filter) => {
			if (filter?.value === data.value) {
				filter.isSelected = !filter?.isSelected;
			}
			return filter;
		});
		handleFilterChange(updateFilter);
	};

	const clearAllFilters = () => {
		setSelectedFilters([]);
		selectedFilterLabel.current = '';
	};

	const onSearchChange = (e) => {
		debounceHandler(e.target.value, onSearch);
	};

	return (
		<>
			<section className="employee_table_header_wrapper">
				<div className="search_and_download_wrapper">
					<Input
						onInput={onSearchChange}
						className=" employee_manage_table_search"
						prefix={<ImageComponent src={icons.not_focus.Search} />}
						placeholder="Search by Name, Employee ID, Designation..."
					/>
					{renderBasedOnClaims?.downloadEmployeeButton && (
						<>
							<Col>
								<EdenredButtons
									loading={employeeListForDownload?.loading}
									onClick={() => {
										getEmployeeDetailsForDownload();
										handleDowloademployee();
									}}
									type="outline-g"
									leftIcon={<ImageComponent className="leftIcon" src={not_focus_icons.documentDownload_v2} />}
									btnType="secondary">
									Download Employee List
								</EdenredButtons>
								<ExcelDownload
									dataSet={employeeListForDownload?.data}
									columnKeys={columnKeys}
									className="view-salary-history"
									filename={`employeelist`}
									activeTabs={activeTabs}
									element={<button style={{ display: 'none' }} ref={buttonRef}></button>}
								/>
							</Col>
							{/* It only visible to need attention tab */}
							{selectedTab === needAttention.key && (
								<Col>
									<FilterButton
										style={{ width: 'auto' }}
										{...{
											value: selectedFilters,
											filterData: _filterData,
											openedFilter: selectedFilterLabel.current,
											selectedFilter: onFilterValueSelected,
											filterClearAll: () => {
												clearAllFilters();
												handleClearAllFilter();
											},
										}}
									/>
								</Col>
							)}
						</>
					)}
				</div>
				<div className="no_of_data_to_show">
					<p>show</p>
					<MultiSelect
						onChange={(data) => setDataCountToShow(parseInt(data?.name))}
						Option={[
							{
								name: '10',
							},
							{
								name: '20',
							},
							{
								name: '30',
							},
						]}
						searchVisibility={false}
						value={`${dataCountToShow}`}
						dropDownValue={'name'}
						multiSelect={false}
					/>
					<p>Entries</p>
				</div>
			</section>
		</>
	);
};

const EmployeeTable = ({
	selectedTab,
	creatingAccountPagination,
	needAttentionPagination,
	deletedAccountPagination,
	activeAccountPagination,
	columns,
	dataSource,
	totalCount,
	handlePaginationChange,
	tableLoading,
	onSearch,
	employeeListForDownload,
	getEmployeeDetailsForDownload,
	handleDowloademployee,
	setDataCountToShow,
	dataCountToShow,
	columnKeys,
	activeTabs,
	buttonRef,
	renderBasedOnClaims,
	filterData,
	handleFilterChange,
	handleClearAllFilter,
}) => {
	const _currentTabPageSize = () => {
		switch (selectedTab) {
			case activeAccounts.key:
				return activeAccountPagination?.pagination?.pageSize || 10;
			case needAttention.key:
				return needAttentionPagination?.pagination?.pageSize || 10;

			case creatingAccounts.key:
				return creatingAccountPagination?.pagination?.pageSize || 10;

			case deletedAccounts.key:
				return deletedAccountPagination?.pagination?.pageSize || 10;

			default:
				return 1;
		}
	};

	const _currentTabCurrentPage = () => {
		switch (selectedTab) {
			case activeAccounts.key:
				return activeAccountPagination?.pagination?.current || 1;
			case needAttention.key:
				return needAttentionPagination?.pagination?.current || 1;

			case creatingAccounts.key:
				return creatingAccountPagination?.pagination?.current || 1;

			case deletedAccounts.key:
				return deletedAccountPagination?.pagination?.current || 1;

			default:
				return 0;
		}
	};

	const _currentPage = () => {
		switch (selectedTab) {
			case activeAccounts.key:
				return 'activeAccountPagination';
			case creatingAccounts.key:
				return 'creatingAccountPagination';

			case needAttention.key:
				return 'needAttentionPagination';

			case deletedAccounts.key:
				return 'deletedAccountPagination';

			default:
				return '';
		}
	};

	// If the total employees count exceeds 50 we dont render the data
	const tableDataSource = dataSource?.length < 50 ? dataSource : [];

	return (
		<>
			<section className="employee_manage_table_wrapper">
				<EmployeeTableHeader
					onSearch={onSearch}
					employeeListForDownload={employeeListForDownload}
					getEmployeeDetailsForDownload={getEmployeeDetailsForDownload}
					handleDowloademployee={handleDowloademployee}
					setDataCountToShow={setDataCountToShow}
					dataCountToShow={dataCountToShow}
					columnKeys={columnKeys}
					activeTabs={activeTabs}
					buttonRef={buttonRef}
					renderBasedOnClaims={renderBasedOnClaims}
					selectedTab={selectedTab}
					filterData={filterData}
					handleFilterChange={handleFilterChange}
					handleClearAllFilter={handleClearAllFilter}
				/>
				<TableV2 columns={columns} data={tableDataSource} tableLoading={tableLoading} />
				<dev className="pagination-bar employee_table_pagination_wrapper ">
					<ETypo light b3 color="#484B52">
						Showing {tableDataSource?.length} out of {totalCount} employees
					</ETypo>
					<Pagination
						className="pagination-bar padding_0"
						currentPage={_currentTabCurrentPage()}
						totalCount={totalCount || 1}
						pageSize={_currentTabPageSize()}
						onPageChange={(page) => handlePaginationChange({ current: page }, _currentPage())}
					/>
				</dev>
			</section>
		</>
	);
};

export default ManageEmployeePresentational;

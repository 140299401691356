import React, { useState } from 'react';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ETypo from '@pages/DesignSystem/Typo';
import { not_focus_icons } from '@assets/icons/not_focus';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';

const PaymentFlowPopup = ({
	isOpen = false,
	onClose = () => {},
	title = '',
	setPaymentCheckFlowCompleted,
	setTransferCodePopup,
	setFeatuerExist = () => {},
}) => {
	const initialState = [
		{
			title: 'Bank Transfer',
			key: 'bank',
			description: 'Select this option if you have done bank transfer',
			isSelected: false,
			id: 1,
		},
		{
			title: 'Cheque Deposit',
			key: 'cheque',
			description: 'Select this option if you have submitted a cheque',
			isSelected: false,
			id: 2,
		},
	];

	const [paymentData, setPaymentData] = useState(initialState);

	const handlePopupCard = (key) => {
		const selectedData = paymentData?.map((data) => {
			return {
				...data,
				isSelected: key === data?.key,
			};
		});
		setPaymentData(selectedData);
	};
	const handleNext = () => {
		const selectedData = paymentData.find((data) => data.isSelected);
		if (selectedData.key === 'cheque') {
			setPaymentCheckFlowCompleted(true);
		} else {
			setTransferCodePopup(true);
		}
		onClose(false);
		setPaymentData(initialState);
	};

	const onCloseModal = () => {
		setFeatuerExist(null);
		onClose(false);
		setPaymentData(initialState);
	};

	return (
		<Edenredmodal className="payroll_wps_nonwps" isOpen={isOpen} title={title} close={onCloseModal}>
			<div className="payroll_wps_nonWps">
				<ETypo bold h4>
					How did you make the payment?
				</ETypo>
				<div className="payroll_wps_nonWps_popup">
					{paymentData?.map((data) => (
						<div
							key={data?.id}
							onClick={() => handlePopupCard(data.key)}
							className={`payroll_popup_card1 ${data?.isSelected ? 'selected' : 'not_selected'}`}>
							<ETypo bold h4>
								{data?.title}
							</ETypo>
							<ETypo light b1 color="#737780">
								{data?.description}
							</ETypo>
							{data?.isSelected && (
								<div className="red_tick">
									<img src={not_focus_icons?.RedTick} />
								</div>
							)}
						</div>
					))}
				</div>
				<div className="payroll_wps_nonWps_popup_button">
					<EdenredButtons disabled={paymentData.every((p) => !p.isSelected)} onClick={handleNext}>
						Next
					</EdenredButtons>
				</div>
			</div>
		</Edenredmodal>
	);
};

export default PaymentFlowPopup;

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'; // or use appropriate router hook if not using React Router
import { SITEMAP } from 'src/routes/sitemap';
import { manageEmployeesActions } from '../manage-employees-table/action';

const useCheckNewEstablishmentId = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const establishmentEmployees = useSelector((state) => state?.manageEmployees?.isNewEstablishmentId);
	const individualEmployeeDocumentValidation = useSelector((state) => state?.manageEmployees?.addIndividualEmployee.documentDetails);
	const profileUpdateStatus = useSelector((state) => state?.manageEmployees?.profileUpdate);

	const goToTradeLicensePage = (action = '', state = {}) => {
		history.replace({ pathname: SITEMAP.employees.uploadTradeLicense, state: { ...location.state, ...state, action } });
	};

	const redirectPage = (path, tradeLicensed = false) => {
		history.replace({ pathname: path, state: { ...location.state, tradeLicensed } });
	};

	const checkEstablishmentId = (payload = []) => {
		dispatch(manageEmployeesActions.creators.checkEstablishmentIdFound(payload));
	};
	const callEstablishmentId = (isCalled = false) => {
		dispatch(manageEmployeesActions.creators.isEstablishmentIdFoundCalled(isCalled));
	};
	const clearEstablishmentId = () => {
		dispatch(manageEmployeesActions.creators.clearEstablishmentIdFound());
	};

	const setEstablishmentIdData = (data) => {
		dispatch(manageEmployeesActions.creators.setEstablishmentIdFound(data));
	};
	const uploadTradeLicense = (data) => {
		dispatch(manageEmployeesActions.creators.uploadTradeLicense(data));
	};
	const updateTradeLicenseStatus = (data) => {
		dispatch(manageEmployeesActions.creators.updateTradeLicenseStatus(data));
	};
	const validateEmployeeDocument = (data) => {
		dispatch(manageEmployeesActions.creators.employeeDocumentValidationStart(data));
	};

	return {
		checkEstablishmentId,
		goToTradeLicensePage,
		establishmentEmployees,
		clearEstablishmentId,
		setEstablishmentIdData,
		callEstablishmentId,
		redirectPage,
		updateTradeLicenseStatus,
		uploadTradeLicense,
		validateEmployeeDocument,
		individualEmployeeDocumentValidation,
		profileUpdateStatus,
	};
};

export default useCheckNewEstablishmentId;

import React, { useEffect, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import Modal from 'react-modal';
import { PulseLoader } from 'react-spinners';
import moment from 'moment';
import { getMonth, getYears, onlyNumberForNumberType } from '@helpers';
import EdenRedCard from '@sharedComponent/card';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import EDragger from '@pages/DesignSystem/Dragger';
import Inputbox from '@pages/DesignSystem/Input-Field';
import HintCard from '@pages/DesignSystem/hindcard';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import DatePickers from '@pages/DesignSystem/date-flat-picker';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import PayrollService from 'src/services/payroll-service';
import ClevertapReact from 'src/utils/clever-tap';
import Toast from '@pages/DesignSystem/Toast';
import CustomDragger from './CustomDragger';
import PaymentFlowPopup from './PaymentFlowPopup';
import ImageComponent from '@sharedComponent/image-component';
import { useHistory } from 'react-router-dom';
import { icons } from '@assets/icons';
import { useSelector } from 'react-redux';
import { CorporateService } from 'src/services';

const { TabPane } = Tabs;

const ProofOfTransferPresentational = () => {
	const _payrollService = new PayrollService();
	const monthDDList = [
		{ index: 0, label: 'January', isSelected: false },
		{ index: 1, label: 'February', isSelected: false },
		{ index: 2, label: 'March', isSelected: false },
		{ index: 3, label: 'April', isSelected: false },
		{ index: 4, label: 'May', isSelected: false },
		{ index: 5, label: 'June', isSelected: false },
		{ index: 6, label: 'July', isSelected: false },
		{ index: 7, label: 'August', isSelected: false },
		{ index: 8, label: 'September', isSelected: false },
		{ index: 9, label: 'October', isSelected: false },
		{ index: 10, label: 'November', isSelected: false },
		{ index: 11, label: 'December', isSelected: false },
	];
	const [Months, setMonths] = useState(monthDDList);
	const [selectedYear, setSelectedYear] = useState();
	const [selectedMonth, setSelectedMonth] = useState();

	let Dates = getMonth(selectedMonth, selectedYear);
	const [selectedDate, setSelectedDate] = useState(new Date().getDate());
	const [modalDrag, setModalDrag] = useState(false);
	const [selectedFile, setselectFile] = useState({});
	const [selectedAmount, setselectedAmount] = useState();
	const [loading, setloading] = useState(false);
	const [proof, setproof] = useState([]);
	const [contentLoading, setcontentLoading] = useState(true);
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({ titleMessage: '', subMessage: '' });
	const [isupload, setIsupload] = useState(false);
	const [isFileSize, setIsFileSize] = useState(false);
	const [tabClicked, setTabClicked] = useState('1');
	const [proofDateSelected, setProofDateSelected] = useState('');
	const [toastMessageShow, setToastMessageShow] = useState(false);
	const [featureExist, setFeatuerExist] = useState(null);
	const [fileDisabled, setFileDisabled] = useState(true);
	const [apiLoading, setApiLoading] = useState(false);
	const [paymentCheckFlowCompleted, setPaymentCheckFlowCompleted] = useState(false);
	const [paymentCheckFlowInitialPopup, setPaymentCheckFlowInitialPopup] = useState(false);
	const [transferCodePopup, setTransferCodePopup] = useState(false);
	const [proofNotNeededPopup, setProofNotNeededPopup] = useState(false);
	const history = useHistory();
	const transferCode = useSelector((state) => state.sharedStates.transferCode) || null;

	const getAllFundTransferHistory = (page, firstTime = false) => {
		let params = {};
		params['pageNumber'] = page ? page : 1;
		params['pageSize'] = 20;
		setcontentLoading(true);
		_payrollService
			.getFundTransferHistory(params)
			.then((res) => {
				setcontentLoading(false);
				if (res && res.data && res.data?.receipts) {
					let resArr = [];
					res.data.receipts?.length &&
						res.data.receipts.map((receipt) => {
							let receiptObj = {};
							receiptObj.id = receipt.id;
							receiptObj.header = `AED ${receipt.amount}`;
							receiptObj.title = moment(receipt.depositDate).format('DD MMMM YYYY'); //'21 July 2020';
							receiptObj.detail = receipt.statusDescription;
							// rejection reason we getting from the notes property
							receiptObj.reason = receipt?.notes;
							receiptObj.background = receipt.color === '#07b841' ? '#8EF9B3' : receipt.color === '#E4DD3D' ? '#FFDC91' : '#FF9691';
							receiptObj.fileName = receipt.fileName;
							receiptObj.image =
								receipt?.status === 'Approved'
									? focus_state_icons?.hrDocumentUptoDate
									: receipt?.status === 'Submitted'
									? focus_state_icons?.payrollTransferHistory
									: focus_state_icons?.hrDocumentExpired;
							resArr.push(receiptObj);
						});
					firstTime ? setproof([...resArr]) : setproof([...proof, ...resArr]);
				}
			})
			.catch((error) => {
				setcontentLoading(false);
				setproof([]);
			});
	};

	useEffect(() => {
		handleChangeYear(new Date().getFullYear(), 1);
	}, []);

	useEffect(() => {
		if (proof.length > 0) tableScrollEvent();
	}, [proof.length > 0]);

	const [pageNumber, setPageNumber] = useState(1);
	useEffect(() => {
		if (contentLoading) {
			getAllFundTransferHistory(pageNumber);
		}
	}, [pageNumber]);

	const setCallPageNumber = async (page) => {
		setPageNumber(page);
	};

	const tableScrollEvent = () => {
		const node = document.querySelector('.fund_transfer_history');

		if (node) {
			node.addEventListener('scroll', async () => {
				const perc = (await (node.scrollTop / (node.scrollHeight - node.clientHeight))) * 100;
				if (perc >= 99.9) {
					await setCallPageNumber((pageNumber) => pageNumber + 1);
				}
			});
		}
	};

	useEffect(() => {
		if (featureExist === false) {
			setPaymentCheckFlowCompleted(true);
			setFileDisabled(false);
		}
		if (featureExist === true) {
			setPaymentCheckFlowInitialPopup(true);
		}
		return () => {
			setPaymentCheckFlowCompleted(false);
			setPaymentCheckFlowInitialPopup(false);
		};
	}, [featureExist]);

	useEffect(() => {
		Modal.setAppElement('body');
	}, [Modal]);

	const handleChangeYear = async (year, defalut = 0) => {
		setSelectedYear(year);
		let currentYear = new Date().getFullYear();
		let date = new Date().setFullYear(year);
		let currentIndex = new Date(date).getMonth();
		let newMonth = [];
		if (currentYear == year) {
			newMonth = monthDDList.filter((value) => {
				if (value.index <= currentIndex) {
					return value;
				}
			});
			setSelectedDate(new Date().getDate());
			setSelectedMonth(newMonth[newMonth?.length - 1].label);
		} else {
			newMonth = monthDDList;
		}
		setMonths([...newMonth]);
		if (defalut == 1) {
			let month = monthDDList.filter((value) => value.index == new Date().getMonth());
			if (month && month[0].label) {
				setSelectedMonth(month[0].label);
			}
		}
		if (currentYear != year) {
			setSelectedMonth(monthDDList[0].label);
			setSelectedDate(Dates[0]);
		}
	};
	const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];

	const handleDragger = (file) => {
		const isLt4M = file.size / 1024 / 1024 < 4;
		setselectFile(file);
		if (allowedFileTypes.indexOf(file.type) >= 0) {
			setIsupload(true);
			setIsFileSize(false);
			setModalDrag(false);
		} else if (isLt4M) {
			setIsupload(false);
			setIsFileSize(true);
			setModalDrag(false);
		} else {
			setIsupload(false);
			setIsFileSize(false);
			setModalDrag(true);
		}
	};
	const onRemoveUpload = () => {
		setselectFile({});
		setModalDrag(false);
		setIsupload(false);
		setIsFileSize(false);
	};

	const onSubmitProof = async (request) => {
		let formData = new FormData();
		formData.append('receiptFile', selectedFile);
		formData.append('amount', request.amount);
		formData.append('depositDate', request.depositDate);
		setloading(true);
		_payrollService
			.submitProof(formData)
			.then((res) => {
				if (res) {
					setloading(false);
					ClevertapReact.event('Submit_Proof', {
						amount: request.amount,
						depositDate: request.depositDate,
					});
					setpopupVisible(true);
					setPopupMessage({
						titleMessage: (
							<div>
								<div>Thanks for submitting your proof of funds!</div>
								<div>You do not need to send us a confirmation mail.</div>
								<div>We will update your balance and release your salaries automatically.</div>
							</div>
						),
						subMessage: null,
					});
					setFeatuerExist(null);
					resetFields();
				} else {
					setloading(false);
				}
				getAllFundTransferHistory(null, true);
			})
			.catch((err) => {
				setloading(false);
			});
	};

	const resetFields = () => {
		setselectFile({});
		setSelectedDate(new Date().getDate());
		handleChangeYear(new Date().getFullYear(), 1);
		getAllFundTransferHistory();
		setModalDrag(false);
		setselectedAmount();
		setIsupload(false);
		setIsFileSize(false);
		setProofDateSelected('');
		setselectedAmount('');
	};

	const tableclicked = (e) => {
		setTabClicked(e);
		if (e == 2) {
			if (proof.length > 0) {
				setTimeout(() => {
					tableScrollEvent();
				});
			}
		}
	};

	const closePopup = () => {
		setpopupVisible(false);
	};

	const draggerIcon = () => {
		if (isupload) {
			return focus_state_icons.NewDesignCheck;
		} else if (isFileSize || modalDrag) {
			return focus_state_icons.NewDesignErro;
		} else {
			return focus_state_icons.hrUpload;
		}
	};
	const draggerStatus = () => {
		if (isupload) {
			return 'done';
		} else if (isFileSize || modalDrag) {
			return 'error';
		} else {
			return 'new';
		}
	};
	const handleProofDate = (dateObj, formatStr) => setProofDateSelected(formatStr);
	const closeToast = () => {
		setToastMessageShow(false);
	};

	async function checkCorporates() {
		try {
			setApiLoading(true);
			const corporateService = new CorporateService();
			const res = await corporateService.getFeatureStatus('ProofOfFundsPopUp');
			if (res.data?.isActive) {
				setFeatuerExist(true);
			} else {
				setFeatuerExist(false);
			}
			setApiLoading(false);
		} catch (error) {
			setApiLoading(false);
		}
	}

	return (
		<>
			<Toast visible={toastMessageShow} color="#8EF9B3" textcolor="#484B52" clearPopUp={closeToast}>
				Your proof of funds has been deleted.
			</Toast>
			<PaymentFlowPopup
				isOpen={paymentCheckFlowInitialPopup}
				onClose={setPaymentCheckFlowInitialPopup}
				setFeatuerExist={setFeatuerExist}
				setPaymentCheckFlowCompleted={setPaymentCheckFlowCompleted}
				setTransferCodePopup={setTransferCodePopup}
			/>
			<Edenredmodal
				isOpen={transferCodePopup}
				title={`Did You Use Transfer Code : ${transferCode}`}
				onOkay={() => {
					setTransferCodePopup(false);
					setProofNotNeededPopup(true);
				}}
				onCancel={() => {
					setTransferCodePopup(false);
					setPaymentCheckFlowCompleted(true);
				}}
				topImage={<ImageComponent src={icons.focus_state.InvoiceFocusWithGrayBg} />}
				desc="If you have used Transfer code in your transfer, it will be auto reconciled and no need to submit proof of funds."
			/>
			<Edenredmodal
				isOpen={proofNotNeededPopup}
				title="You should not Upload Proof of Transfer Funds"
				onOkayButton="Go To Dashboard"
				onOkay={() => {
					history.push('/');
					setProofNotNeededPopup(false);
				}}
				topImage={<ImageComponent src={icons.focus_state.walletTickGreyBg} />}
				desc="Since you used the transfer code, the payment will be auto-reconciled, and no proof of funds is needed."
			/>

			<Edenredmodal isOpen={popupVisible} title={popupMessage?.titleMessage} onOkayButton="Ok" onOkay={closePopup} />
			<Col className="proof_of_transfer_presentational_container">
				<EdenredHeader />
				<Row className="Proof-of-Transfer-presentational">
					<Col className="proof_of_find_transfer_header">
						<ETypo bold h2>
							Proof of Funds Transfer
						</ETypo>
						{tabClicked === '1' && (
							<EdenredButtons
								loading={loading}
								disabled={!(selectedFile && selectedFile.name) || !proofDateSelected || !selectedAmount}
								onClick={() => {
									const format1 = 'YYYY-MM-DDThh:mm:ss';
									const dateTime = moment(proofDateSelected, 'DD/MM/YYYY').format(format1);
									let reqObj = {};
									reqObj.attachment = selectedFile.name;
									reqObj.depositDate = dateTime;
									reqObj.amount = selectedAmount;
									onSubmitProof(reqObj);
								}}>
								Submit
							</EdenredButtons>
						)}
					</Col>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="proof_of_find_transfer_tab">
						<Tabs defaultActiveKey="1" onTabClick={tableclicked} size="small">
							<TabPane tab="Submit Proof" key="1">
								<Col className="upload_proof_of_fund_transfer">
									<Col xl={24} lg={24} md={24} sm={24} xs={24} className="upload">
										<ETypo bold h2 className="proof_of_fund_text">
											Upload Proof Of Funds Transfer:
										</ETypo>
									</Col>
									<Col xl={24} lg={24} md={24} sm={24} xs={24} className="cheques_mobile_view">
										Cheques may take up to 3 working days to clear before payroll can be run.
									</Col>
									<Col xl={24} lg={24} md={24} sm={24} xs={24} className="dragger_submit">
										{paymentCheckFlowCompleted ? (
											<EDragger
												{...{
													idleIcon: draggerIcon(),
													draggerStatus: draggerStatus(),
													uploadDeleteIcon: focus_state_icons.NewDelete,
													onChange: handleDragger,
													onRemoveUpload,
													acceptedTypes: '.jpg,.jpeg,.png,.pdf',
													fileFormat: '(JPG, JPEG, PNG, PDF) ',
													fileMaxSize: 'Max file size: 4Mb',
												}}
											/>
										) : (
											<div onClick={checkCorporates}>
												<CustomDragger
													{...{
														idleIcon: draggerIcon(),
														draggerStatus: draggerStatus(),
														uploadDeleteIcon: focus_state_icons.NewDelete,
														onChange: handleDragger,
														onRemoveUpload,
														acceptedTypes: '.jpg,.jpeg,.png,.pdf',
														fileFormat: '(JPG, JPEG, PNG, PDF) ',
														fileMaxSize: 'Max file size: 4Mb',
														fileDisabled: fileDisabled,
														loading: apiLoading,
													}}
												/>
											</div>
										)}
									</Col>
									{(isFileSize || modalDrag) && (
										<Col style={{ marginTop: '10px' }}>
											<HintCard
												{...{
													icon: focus_state_icons.hrDocumentExpired,
													bgColor: '#fff6f5',
													hint: isFileSize
														? 'File must be smaller than 4MB!'
														: modalDrag
														? `Invalid format. Please upload one of these formats ${allowedFileTypes.join()}`
														: '',
												}}
											/>
										</Col>
									)}
									<Col xl={24} lg={24} md={24} sm={24} xs={24} className="ProofOfTransfer_payment">
										<Col xl={6} md={6} lg={6} sm={8} xs={24}>
											<Col xl={24} lg={24} md={24} sm={24} xs={24} className="Payment_amount">
												<Inputbox
													label="Payment Amount On Slip"
													prefix={'AED'}
													onChange={(e) => setselectedAmount(e.target.value)}
													type="number"
													onKeyDown={(event) => onlyNumberForNumberType(event)}
													value={selectedAmount}
													placeholder="Enter amount on slip"
												/>
											</Col>
										</Col>
										<Col xl={6} lg={6} md={6} sm={24} xs={24} className="data_slip">
											<DatePickers
												{...{
													label: 'Date of slip:',
													value: proofDateSelected,
													maxDate: 'today',
													onChange: (dateObj, formatStr) => handleProofDate(dateObj, formatStr),
												}}
											/>
										</Col>
									</Col>
									<Col xl={24} lg={24} md={24} sm={24} xs={24} className="cheques">
										<ETypo light b1 color="#484B52">
											Cheques may take up to 3 working days to clear before payroll can be run.
										</ETypo>
									</Col>
								</Col>
							</TabPane>
							{/* fund transfer */}
							<TabPane
								onClick={(e) => {
									e.preventDefault();
									setCallPageNumber(pageNumber);
								}}
								tab="Fund Transfer History"
								key="2">
								<Col className="fund_transfer_history">
									<Col xl={24} lg={24} md={24} sm={24} xs={24} className="once_confirm">
										Once we confirm that we have received your salary funds, we’ll run payroll immediately.
									</Col>
									<Col xl={24} lg={24} md={24} sm={24} xs={24}>
										{proof && proof?.length > 0 ? (
											proof?.map((user, index) => (
												<Col key={index} className="fund_transfer_tab2" style={{ background: user?.background }}>
													<EdenRedCard
														setToastMessageShow={setToastMessageShow}
														refreshData={() => getAllFundTransferHistory(null, true)}
														className="card_history"
														type="proof_of_transfer"
														getAllFundTransferHistory={getAllFundTransferHistory}
														content={user}
													/>
												</Col>
											))
										) : (
											<div style={{ textAlign: 'center' }}>No Records Found</div>
										)}
										{contentLoading && (
											<Col style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
												<PulseLoader size={20} color={'#e91d24'} loading />
											</Col>
										)}
									</Col>
								</Col>
							</TabPane>
						</Tabs>
					</Col>
				</Row>
			</Col>
		</>
	);
};
export default ProofOfTransferPresentational;

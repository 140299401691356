import React, { useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { APP_VARIABLES } from '@constants/app-constants';
import ImageComponent from '@sharedComponent/image-component';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';
import TradeLicenseBanner from '@pages/employees/manage-employees/manage-employees-table/components/TradeLicense/TradeLicenseBanner';

const statuses = [
	{
		name: 'Verifier Approve',
		icon: icons.focus_state.nextStepEmail,
		status: 'Employee addition needs to be approved if your approval setting are active.',
	},
	{
		name: 'Employee Registration',
		icon: icons.focus_state.nextStepClock,
		status: 'Employee registration will be completed by the end of each working day.',
	},
	{
		name: 'Notify Email',
		icon: icons.focus_state.nextStepEmailOpen,
		status: 'You’ll receive an e-mail when your employees are registered, so that you can start running payroll for them.',
	},
	{
		name: 'Track Card Status',
		icon: icons.focus_state.nextStepLocation,

		status: (
			<span>
				Track your card order status{' '}
				<Link
					className="Here-text"
					to={{ pathname: '/employees/card/order/status' }}
					target="_blank"
					onClick={() =>
						localStorage.setItem(
							APP_VARIABLES.REDIRECT_TO,
							JSON.stringify({
								url: '/employees/card/order/status',
							})
						)
					}>
					Here
				</Link>{' '}
				You can run payroll for employees befores they receive their cards.
			</span>
		),
	},
];

const MultipleEmployeesNextSteps = () => {
	const history = useHistory();
	const location = useLocation();
	const isComingFromTradeLicensedPage = location.state?.tradeLicensed ?? false;
	let noOfEmployees = useMemo(() => history?.location?.state?.noOfEmployees, [history?.location?.state?.noOfEmployees]);
	const handleRedirect = () => history.push('/employees/manage_employee');

	return (
		<>
			<div className="multiple_employees_next_steps_container">
				<div className="multiple-emp-next-step-section">
					<div className="multiple_employees_next_steps_header">
						<ETypo h2 bold color="#00344E">
							Add Employees
						</ETypo>
						<EdenredButtons className="back-button-next-setup" btnType="secondary" onClick={handleRedirect}>
							<ImageComponent src={images.leftsarrow} style={{ marginRight: '4px' }}></ImageComponent>
							<ETypo b1 color="#484B52">
								Return to Manage Employees
							</ETypo>
						</EdenredButtons>
					</div>
					{isComingFromTradeLicensedPage && <TradeLicenseBanner />}
					<div className="add-emp-next-step-card-container">
						<div className="add-emp-next-step-sub-header">
							<ETypo h4 medium color="#00344E">
								Next Steps
							</ETypo>
							<ETypo b2 light color="#484B52">
								You've added {noOfEmployees} employees
							</ETypo>
						</div>

						{statuses?.map((res) => (
							<div key={res?.name} className="add-emp-next-step-section">
								<ImageComponent src={res.icon}></ImageComponent>
								<ETypo b1 light color="#484B52">
									{res?.status}
								</ETypo>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default MultipleEmployeesNextSteps;

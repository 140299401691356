import Axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { APP_VARIABLES, DATE_FORMAT, DOMAIN_URL } from '@constants/app-constants';
import { templateDownload } from '@helpers';
import { manageEmployeesActions } from '../action';
import moment from 'moment';
import { message } from 'antd';

export function* updateEmailData(initial) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const isPortalInviteNull = initial?.payload?.portalInviteStatus;
	const request = {
		phoneNumber: initial?.payload?.mobileNumber || '',
		emailId: initial?.payload?.emailId || '',
		employeeId: initial?.payload?.employeeCode,
		userFirstLastName: initial?.payload?.employeeName,
	};
	const request1 = {
		emailId: initial?.payload?.emailId || '',
		employeeId: initial?.payload?.employeeCode,
	};
	const forPayLoad = isPortalInviteNull === null ? request : request1;
	try {
		const url =
			isPortalInviteNull === null
				? `${DOMAIN_URL}/Users/users/${corporateId}/create-employee-user`
				: `${DOMAIN_URL}/Users/${corporateId}/re-invite-employee`;
		const res = yield call(Axios.post, url, forPayLoad);
		yield put(manageEmployeesActions.creators.postEmailDataSuccess(res.data));
		yield put(manageEmployeesActions.creators.invitePortalPopup(false));
		yield put(manageEmployeesActions.creators.inviteCardStatus(res.status === 200 ? 'Success' : 'NotSuccess'));
	} catch (err) {
		yield put(manageEmployeesActions.creators.postEmailDataError(err));
		yield put(manageEmployeesActions.creators.invitePortalPopup(false));
		yield put(manageEmployeesActions.creators.inviteCardStatus('NotSuccess'));
	}
}
export function* updateEmailServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const emp_Id = action?.payload?.employeeId;
	const email = action?.payload?.emailId;
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/${emp_Id}/update-email?emailId=${email}`;
		const res = yield call(Axios.patch, uri);
		yield put(manageEmployeesActions.creators.updateEmailNewSuccess(res?.data));
	} catch (err) {
		yield put(manageEmployeesActions.creators.updateEmailNewFail(err));
	}
}

export function* getEmployeeNewDataSaga() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const url = `${DOMAIN_URL}/Employees/${corporateId}/data/download`;
		const res = yield call(Axios.get, url, {
			responseType: 'blob',
		});
		yield put(manageEmployeesActions.creators.getEmployeeNewDataSuccess(res?.data));
		templateDownload(res?.data, 'employee.xlsx');
	} catch (err) {
		yield put(manageEmployeesActions.creators.getEmployeeNewDataError(err));
	}
}

export function* addNewEstablishmentIdData(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const url = `${DOMAIN_URL}/Employees/${corporateId}/establishment-id-check`;
		const payload = Array.isArray(action.payload) ? action.payload : [...action.payload];
		const res = yield call(Axios.post, url, payload);
		yield put(manageEmployeesActions.creators.clearEmployeeDocumentValidationData());
		yield put(manageEmployeesActions.creators.setEstablishmentIdFound(res?.data));
	} catch (err) {
		yield put(manageEmployeesActions.creators.establishmentIdFoundError({ error: true, data: String(err) }));
		yield put(manageEmployeesActions.creators.clearEmployeeDocumentValidationData());
		console.error(err);
	}
	// calling establishment id found called
	yield put(manageEmployeesActions.creators.isEstablishmentIdFoundCalled(true));
}
export function* uploadTradeLicense(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const url = `${DOMAIN_URL}/Employees/${corporateId}/trade-license-upload `;
		const payload = action.data;
		const res = yield call(Axios.post, url, payload);
		// if the response returns not successful
		if (!res.data?.isSuccessful && res.data?.errorMessage) {
			message.error(String(res.data.errorMessage), 5);
		}
		yield put(manageEmployeesActions.creators.updateTradeLicenseStatus(res?.data));
	} catch (err) {
		yield put(
			manageEmployeesActions.creators.updateTradeLicenseStatus({
				isSuccessful: false,
				errorMessage: String(err),
			})
		);
		message.error(String(err), 5);
		console.error(err);
	}
}
export function* validateIndividualDocument(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const res = action?.payload;
	let joiningDate = res?.joiningDate ? moment(res?.joiningDate, 'DD/MM/YYYY').format(DATE_FORMAT?.YYYY_MM_DD) : null;
	const payload = {
		employeeId: res?.empId,
		wpsPersonId: res?.wpsPersonId,
		wpsEstablishmentId: res?.wpsEstablishmentId,
		labourCardNumber: res?.labourCardNumber,
		emiratesId: res?.emiratesId,
		passportNumber: res?.passportNumber,
		bankName: res?.bankName || '',
		iban: res?.Iban || '',
		emiratesIdExpiryDate: moment().format(DATE_FORMAT?.YYYY_MM_DD),
		flag: '3',
		joiningDate: joiningDate,
		contractEndDate: res?.endDate ? moment(res?.endDate, 'DD/MM/YYYY').format(DATE_FORMAT?.YYYY_MM_DD) : '',
		designation: res?.designation,
		department: res?.department,
		contractType: res?.contractName?.toLowerCase(),
		basicSalary: 0,
		firstName: res?.firstName,
		lastName: res?.lastName,
		gender: res?.gender,
		dateofBirth: moment(res?.dob, 'DD/MM/YYYY').format(DATE_FORMAT?.YYYY_MM_DD),
		mobileNumber: res?.mobile,
		nationality: res?.nationality,
		email: res?.email,
		salaryProcessingChannel: res?.account,
	};
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/individual-employee/validate`;
		const res = yield call(Axios.post, uri, payload);
		const errorData = res.data?.validationMessage; // error validation data
		// If found any error will update the errordata
		if (errorData?.length > 0) {
			yield put(
				manageEmployeesActions.creators.employeeDocumentValidationData({
					error: true,
					errorData,
				})
			);
		} else {
			// if no validation error, we can update the success as true
			yield put(
				manageEmployeesActions.creators.employeeDocumentValidationData({
					error: false,
					success: res.status === 200 && res.data === '',
					errorData: null,
				})
			);
		}
	} catch (err) {
		console.error(err);
	}
}

export default function* manageEmployeeWatcher() {
	yield takeEvery(manageEmployeesActions.types.POST_EMAIL_DATA, updateEmailData);
	yield takeEvery(manageEmployeesActions.types.UPDATE_EMAIL_NEW_START, updateEmailServices);
	yield takeEvery(manageEmployeesActions.types.GET_NEW_DATA, getEmployeeNewDataSaga);
	yield takeEvery(manageEmployeesActions.types.CHECK_ESTABLISHMENT_ID_FOUND, addNewEstablishmentIdData);
	yield takeEvery(manageEmployeesActions.types.UPLOAD_TRADE_LICENSE, uploadTradeLicense);
	yield takeEvery(manageEmployeesActions.types.EMPLOYEE_DOCUMENT_VALIDATION_START, validateIndividualDocument);
}

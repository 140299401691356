const POST_EMAIL_DATA = 'POST_EMAIL_DATA';
const POST_EMAIL_DATA_SUCCESS = 'POST_EMAIL_DATA_SUCCESS';
const POST_EMAIL_DATA_ERROR = 'POST_EMAIL_DATA_ERROR';
const UPDATE_EMAIL_NEW_START = 'UPDATE_EMAIL_NEW_START';
const UPDATE_EMAIL_NEW_SUCCESS = 'UPDATE_EMAIL_NEW_SUCCESS';
const UPDATE_EMAIL_NEW_FAIL = 'UPDATE_EMAIL_NEW_FAIL';
const INVITE_PORTAL_POPUP = 'INVITE_PORTAL_POPUP';
const GET_NEW_DATA = 'GET_NEW_DATA';
const GET_NEW_DATA_SUCCESS = 'GET_NEW_DATA_SUCCESS';
const GET_NEW_DATA_ERROR = 'GET_NEW_DATA_ERROR';
const INVITE_CARD_STATUS = 'INVITE_CARD_STATUS';
const CHECK_ESTABLISHMENT_ID_FOUND = 'CHECK_ESTABLISHMENT_ID_FOUND';
const SET_ESTABLISHMENT_ID_FOUND = 'SET_ESTABLISHMENT_ID_FOUND';
const ESTABLISHMENT_ID_FOUND_CALLED = 'ESTABLISHMENT_ID_FOUND_CALLED';
const ESTABLISHMENT_ID_FOUND_ERROR = 'ESTABLISHMENT_ID_FOUND_ERROR';
const CLEAR_ESTABLISHMENT_ID_FOUND = 'CLEAR_ESTABLISHMENT_ID_FOUND';
const UPLOAD_TRADE_LICENSE = 'UPLOAD_TRADE_LICENSE';
const UPDATE_TRADE_LICENSE_STATUS = 'UPDATE_TRADE_LICENSE_STATUS';
const EMPLOYEE_DOCUMENT_VALIDATION_START = 'EMPLOYEE_DOCUMENT_VALIDATION_START';
const EMPLOYEE_DOCUMENT_VALIDATION_DATA = 'EMPLOYEE_DOCUMENT_VALIDATION_DATA';
const CLEAR_EMPLOYEE_DOCUMENT_VALIDATION_DATA = 'CLEAR_EMPLOYEE_DOCUMENT_VALIDATION_DATA';
const PROFILE_UPDATE = 'PROFILE_UPDATE';
const CLEAR_PROFILE_UPDATE = 'CLEAR_PROFILE_UPDATE';

const postEmailData = (initial) => {
	return {
		type: POST_EMAIL_DATA,
		payload: initial,
	};
};
const postEmailDataSuccess = (res) => {
	return {
		type: POST_EMAIL_DATA_SUCCESS,
		res,
	};
};
const postEmailDataError = (err) => {
	return {
		type: POST_EMAIL_DATA_ERROR,
		err,
	};
};
const updateEmailNewStart = (payload) => {
	return {
		type: UPDATE_EMAIL_NEW_START,
		payload,
	};
};
const updateEmailNewSuccess = (res) => {
	return {
		type: UPDATE_EMAIL_NEW_SUCCESS,
		res,
	};
};
const updateEmailNewFail = (error) => {
	return {
		type: UPDATE_EMAIL_NEW_FAIL,
		error,
	};
};
const invitePortalPopup = (flag) => {
	return {
		type: INVITE_PORTAL_POPUP,
		flag,
	};
};
const getEmployeeNewData = () => {
	return {
		type: GET_NEW_DATA,
	};
};

const getEmployeeNewDataSuccess = (res) => {
	return {
		type: GET_NEW_DATA_SUCCESS,
		res,
	};
};

const getEmployeeNewDataError = (res) => {
	return {
		type: GET_NEW_DATA_ERROR,
		res,
	};
};
const inviteCardStatus = (status) => {
	return {
		type: INVITE_CARD_STATUS,
		status,
	};
};

const checkEstablishmentIdFound = (payload) => {
	return {
		type: CHECK_ESTABLISHMENT_ID_FOUND,
		payload,
	};
};

const setEstablishmentIdFound = (data = {}) => {
	return {
		type: SET_ESTABLISHMENT_ID_FOUND,
		data,
	};
};
const isEstablishmentIdFoundCalled = (data = false) => {
	return {
		type: ESTABLISHMENT_ID_FOUND_CALLED,
		data,
	};
};
const clearEstablishmentIdFound = () => {
	return {
		type: CLEAR_ESTABLISHMENT_ID_FOUND,
	};
};
const establishmentIdFoundError = (payload = {}) => {
	return {
		type: ESTABLISHMENT_ID_FOUND_ERROR,
		payload,
	};
};
const uploadTradeLicense = (data = {}) => {
	return {
		type: UPLOAD_TRADE_LICENSE,
		data,
	};
};
const updateTradeLicenseStatus = (data = {}) => {
	return {
		type: UPDATE_TRADE_LICENSE_STATUS,
		data,
	};
};
const employeeDocumentValidationStart = (payload = {}) => {
	return {
		type: EMPLOYEE_DOCUMENT_VALIDATION_START,
		payload,
	};
};
const employeeDocumentValidationData = (payload = {}) => {
	return {
		type: EMPLOYEE_DOCUMENT_VALIDATION_DATA,
		payload,
	};
};
const clearEmployeeDocumentValidationData = () => {
	return {
		type: CLEAR_EMPLOYEE_DOCUMENT_VALIDATION_DATA,
	};
};
const profileUpdate = (payload) => {
	return {
		type: PROFILE_UPDATE,
		payload,
	};
};
const clearProfileUpdate = () => {
	return {
		type: CLEAR_PROFILE_UPDATE,
	};
};

export const manageEmployeesActions = {
	types: {
		POST_EMAIL_DATA,
		POST_EMAIL_DATA_SUCCESS,
		POST_EMAIL_DATA_ERROR,
		UPDATE_EMAIL_NEW_START,
		UPDATE_EMAIL_NEW_SUCCESS,
		UPDATE_EMAIL_NEW_FAIL,
		INVITE_PORTAL_POPUP,
		GET_NEW_DATA,
		GET_NEW_DATA_SUCCESS,
		GET_NEW_DATA_ERROR,
		INVITE_CARD_STATUS,
		CHECK_ESTABLISHMENT_ID_FOUND,
		SET_ESTABLISHMENT_ID_FOUND,
		CLEAR_ESTABLISHMENT_ID_FOUND,
		ESTABLISHMENT_ID_FOUND_CALLED,
		UPLOAD_TRADE_LICENSE,
		UPDATE_TRADE_LICENSE_STATUS,
		EMPLOYEE_DOCUMENT_VALIDATION_START,
		EMPLOYEE_DOCUMENT_VALIDATION_DATA,
		CLEAR_EMPLOYEE_DOCUMENT_VALIDATION_DATA,
		ESTABLISHMENT_ID_FOUND_ERROR,
		PROFILE_UPDATE,
		CLEAR_PROFILE_UPDATE,
	},

	creators: {
		postEmailData,
		postEmailDataSuccess,
		postEmailDataError,
		updateEmailNewStart,
		updateEmailNewSuccess,
		updateEmailNewFail,
		invitePortalPopup,
		getEmployeeNewData,
		getEmployeeNewDataSuccess,
		getEmployeeNewDataError,
		inviteCardStatus,
		checkEstablishmentIdFound,
		setEstablishmentIdFound,
		clearEstablishmentIdFound,
		isEstablishmentIdFoundCalled,
		uploadTradeLicense,
		updateTradeLicenseStatus,
		employeeDocumentValidationStart,
		employeeDocumentValidationData,
		clearEmployeeDocumentValidationData,
		establishmentIdFoundError,
		profileUpdate,
		clearProfileUpdate,
	},
};

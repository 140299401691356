import { icons } from '@assets/icons';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ImageComponent from '@sharedComponent/image-component';
import React, { useState } from 'react';

// This popup will only comes with updating employees through profile/upload spreadsheet
const TradeLicensePopup = ({ onClick = () => {} }) => {
	const [modal, setModal] = useState(true);
	return (
		<Edenredmodal
			title="Trade License Verification in Progress"
			desc="Your trade license verification with the establishment ID is underway. You will receive an email notification once it is successfully verified, within the next 48 hours."
			onOkayButton="Understood"
			onOkay={() => {
				setModal(false);
				onClick();
			}}
			isOpen={modal}
			topImage={<ImageComponent src={icons.focus_state.infoHexagonGreyCircle} />}
		/>
	);
};

export default TradeLicensePopup;

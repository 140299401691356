import { manageEmployeesActions } from './action';

export const initState = {
	isEmailDataLoading: false,
	emailDataSave: [],
	emailUpdated: [],
	emailUpdateError: '',
	isInvitePortalPopup: false,
	employeeNewData: [],
	employeeNewDataError: '',
	isEmployeeNewDataLoading: false,
	inviteCardStatus: '',
	addIndividualEmployee: {
		personalDetails: {},
		documentDetails: {
			error: false,
			success: false,
			errorData: null,
		},
	},
	profileUpdate: {
		error: false,
		success: false,
	},
	// state which used for employee trade license flow
	isNewEstablishmentId: {
		isCalled: false,
		isFound: false,
		establishmentIds: null,
		nextSteps: () => {},
		status: null,
		loading: false,
		error: false,
		errorData: null,
	},
};

const EmployeesReducer = (state = initState, action) => {
	switch (action.type) {
		case manageEmployeesActions.types.POST_EMAIL_DATA: {
			return {
				...state,
				isEmailDataLoading: true,
			};
		}
		case manageEmployeesActions.types.POST_EMAIL_DATA_SUCCESS: {
			return {
				...state,
				isEmailDataLoading: false,
				emailDataSave: action.res,
			};
		}
		case manageEmployeesActions.types.POST_EMAIL_DATA_FAILED: {
			return {
				...state,
				isEmailDataLoading: false,
				emailDataSave: action.err,
			};
		}
		case manageEmployeesActions.types.UPDATE_EMAIL_NEW_START: {
			return {
				...state,
				isEmailUpdateLoad: true,
			};
		}
		case manageEmployeesActions.types.UPDATE_EMAIL_NEW_SUCCESS: {
			return {
				...state,
				isEmailUpdateLoad: false,
				emailUpdated: action?.res,
			};
		}
		case manageEmployeesActions.types.UPDATE_EMAIL_NEW_FAIL: {
			return {
				...state,
				isEmailUpdateLoad: false,
				emailUpdated: [],
				emailUpdateError: action?.error,
			};
		}
		case manageEmployeesActions.types.INVITE_PORTAL_POPUP: {
			return {
				...state,
				isInvitePortalPopup: action?.flag,
			};
		}
		case manageEmployeesActions.types.GET_NEW_DATA: {
			return {
				...state,
				isEmployeeNewDataLoading: true,
			};
		}
		case manageEmployeesActions.types.GET_NEW_DATA_SUCCESS: {
			return {
				...state,
				employeeNewData: action.res,
				isEmployeeNewDataLoading: false,
			};
		}
		case manageEmployeesActions.types.GET_NEW_DATA_ERROR: {
			return {
				...state,
				employeeNewDataError: action.res,
				isEmployeeNewDataLoading: false,
			};
		}
		case manageEmployeesActions.types.INVITE_CARD_STATUS: {
			return {
				...state,
				inviteCardStatus: action.status,
			};
		}
		case manageEmployeesActions.types.SET_ESTABLISHMENT_ID_FOUND: {
			return {
				...state,
				isNewEstablishmentId: {
					...state.isNewEstablishmentId,
					...action.data,
				},
			};
		}
		case manageEmployeesActions.types.ESTABLISHMENT_ID_FOUND_CALLED: {
			return {
				...state,
				isNewEstablishmentId: {
					...state.isNewEstablishmentId,
					isCalled: action.data,
				},
			};
		}
		case manageEmployeesActions.types.ESTABLISHMENT_ID_FOUND_ERROR: {
			return {
				...state,
				isNewEstablishmentId: {
					...state.isNewEstablishmentId,
					error: action.payload.error,
					errorData: action.payload.data,
				},
			};
		}
		case manageEmployeesActions.types.CLEAR_ESTABLISHMENT_ID_FOUND: {
			return {
				...state,
				isNewEstablishmentId: initState.isNewEstablishmentId,
			};
		}
		case manageEmployeesActions.types.UPDATE_TRADE_LICENSE_STATUS: {
			return {
				...state,
				isNewEstablishmentId: {
					...state.isNewEstablishmentId,
					status: action.data,
				},
			};
		}
		case manageEmployeesActions.types.EMPLOYEE_DOCUMENT_VALIDATION_DATA: {
			return {
				...state,
				addIndividualEmployee: {
					...state.addIndividualEmployee,
					documentDetails: {
						...state.addIndividualEmployee.documentDetails,
						...action.payload,
					},
				},
			};
		}
		case manageEmployeesActions.types.CLEAR_EMPLOYEE_DOCUMENT_VALIDATION_DATA: {
			return {
				...state,
				addIndividualEmployee: initState.addIndividualEmployee,
			};
		}
		case manageEmployeesActions.types.PROFILE_UPDATE: {
			return {
				...state,
				profileUpdate: {
					...state.profileUpdate,
					...action.payload,
				},
			};
		}
		case manageEmployeesActions.types.CLEAR_PROFILE_UPDATE: {
			return {
				...state,
				profileUpdate: initState.profileUpdate,
			};
		}

		default:
			return state;
	}
};
export default EmployeesReducer;

import Axios from 'axios';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiUrl, appConstant } from '@constants';
import { getLocalvariable, setLocalvariable, syncLocalStorage } from '@helpers';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';
import payrollAction from '../../../../pages/payroll-plus-new/action';
import { LOCAL_STORAGE_KEYS } from '@constants/localStorage';

export function* rolesWorker() {
	try {
		const uri = `${appConstant.domain}${apiUrl.GET_USER_ROLE}`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getRolesSuccess(res.data.roles));
		const claims = yield all(
			res.data.roles.map((role) => {
				const claims = call(Axios.get, `${appConstant.domain}${apiUrl.GET_CLAIMS}/${role.id}`);
				return claims;
			})
		);
		yield all(
			claims.map((claim) => {
				return put(Actions.creators.getClaimsSuccess(claim.data.role.name, claim.data.claims));
			})
		);
	} catch (err) {
		// let mes = err.toString(err) === 'Error: Request failed with status code 400' ? appConstant.userNotExist : 'Something went wrong';
		// message.error(mes);
		// yield put(Actions.creators.getUser());
	}
}
/**
 * future ref
	  export function* claimsWorker(action) {
	try {
		const uri = `${appConstant.domain}${apiUrl.GET_CLAIMS}`;
		const res = yield call(Axios.get, uri);
		// yield put(Actions.creators.getClaimsSuccess(res.data.roles));
	} catch (err) {
		let mes = err.toString(err) === 'Error: Request failed with status code 400' ? appConstant.userNotExist : 'Something went wrong';
		message.error(mes);
		// yield put(Actions.creators.getUser());
	}
}*/

export function* corporateIdWorker(action) {
	try {
		// yield localStorage.removeItem(APP_VARIABLES.CORPORATE_ID, corporateId);
		const uri = `${appConstant.domain}${apiUrl.CORPORATE_ID}`;
		const res = yield call(Axios.get, uri);
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID)
			? localStorage.getItem(APP_VARIABLES.CORPORATE_ID)
			: res.data.corporates[0].corporateId;
		const corporateName = localStorage.getItem(APP_VARIABLES.CORPORATE_NAME)
			? localStorage.getItem(APP_VARIABLES.CORPORATE_NAME)
			: res.data.corporates[0].corporateName;
		const corporateIds = res.data.corporates;

		/*Subscription Api*/
		const subUri = `${appConstant.domain}${apiUrl.SUBSCRIPTION_URL}/${corporateId}`;
		const subRes = yield call(Axios.get, subUri);
		yield put(Actions.creators.subscriptionSuccess([subRes?.data?.productList || '']));
		yield localStorage.setItem(APP_VARIABLES.PROD_SUBSCRIPTIONS, JSON.stringify([subRes?.data?.productList || '']));

		yield localStorage.setItem(APP_VARIABLES.CORPORATE_ID, corporateId);
		res.data.corporates.map((value, i) => {
			if (value.corporateId === localStorage.getItem(APP_VARIABLES.CORPORATE_ID)) {
				localStorage.setItem('amount', value.amount);
			}
		});
		yield localStorage.setItem(APP_VARIABLES.CORPORATE_ID, corporateId);
		yield localStorage.setItem(APP_VARIABLES.CORPORATE_NAME, corporateName);
		yield put(Actions.creators.getCorporateIdSuccess(corporateId, corporateIds));
	} catch (err) {
		yield put(Actions.creators.getCorporateIdFailed());
	}
}

// export function* getNotificationCountSaga(action) {
// 	const { history } = action;
// 	// const { authKey, authToken } = getKeyAndToken();
// 	try {
// 		const uri = `${appConstant.domain}${apiUrl.CORPORATE_ID}`;
// 		_payrollService.getApproveRequestCountAPI()
// 		// const uri = `${appConstant.domain + apiUrl.GET_NOTIFICATION_COUNT_URL + authKey}`;
// 		const res = yield call(Axios.get, uri, { headers: { Authorization: authToken } });
// 		yield put(Actions.creators.getNotificationCountSuccess(res && res.data && res.data.messages ? res.data.messages : []));
// 	} catch (err) {
// 		yield put(Actions.creators.removeUser(authKey, history));
// 		yield put(Actions.creators.getNotificationCountFail());
// 	}
// }
export function* userNavigationservice() {
	const baseUrl = `${DOMAIN_URL}`;
	try {
		const uri = `${baseUrl}/UserMenuNavigationStatus/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`;
		const res = yield call(Axios.get, uri);
		const bannerResponses = {
			iloeCheckNow: res.data?.iloeCheckNow || false,
			iloeKnowMore: res.data?.iloeKnowMore || false,
			iloeUnderstood: res.data?.iloeUnderstood || false,
		};
		const { iloeCheckNow, iloeKnowMore, iloeUnderstood } = bannerResponses;
		const checkDashboardRenewalKey = syncLocalStorage.getItem(LOCAL_STORAGE_KEYS.iloe.dashboardRenewalBanner);
		const checkBeforeRenewalKey = syncLocalStorage.getItem(LOCAL_STORAGE_KEYS.iloe.beforeRenewal);
		const checkAfterRenewalKey = syncLocalStorage.getItem(LOCAL_STORAGE_KEYS.iloe.afterRenewal);
		function storeRenewalBannerKeys() {
			// settingup local storage values for banners
			if (!checkDashboardRenewalKey && !iloeCheckNow) {
				syncLocalStorage.setItem(LOCAL_STORAGE_KEYS.iloe.dashboardRenewalBanner, JSON.stringify(iloeCheckNow));
			}
			if (!checkBeforeRenewalKey && !iloeKnowMore) {
				syncLocalStorage.setItem(LOCAL_STORAGE_KEYS.iloe.beforeRenewal, JSON.stringify(iloeKnowMore));
			}
			// when the know more flag was true and understood banner was false then this key must need in storage adding if it not present.
			if (!checkBeforeRenewalKey && !checkAfterRenewalKey && iloeKnowMore && !iloeUnderstood) {
				syncLocalStorage.setItem(LOCAL_STORAGE_KEYS.iloe.afterRenewal, JSON.stringify(iloeUnderstood));
			}
		}
		function removeWrongFlagsForBanners() {
			// removing any flags in storage that was user already seen but still persist in storage
			if (iloeCheckNow && checkDashboardRenewalKey) {
				syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.dashboardRenewalBanner);
			}
			if (iloeKnowMore && checkBeforeRenewalKey) {
				syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.beforeRenewal);
			}
			if (iloeUnderstood && checkAfterRenewalKey) {
				syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.afterRenewal);
			}
		}
		storeRenewalBannerKeys();
		removeWrongFlagsForBanners();
		yield put(Actions.creators.getUserNavigationSuccess(res.data));

		/**
		 * Group invoice
		 */
		const showInvoiceMenuUri = `${baseUrl}/InvoiceReceipt/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/CheckInvoice`;
		const showInvoiceRes = yield call(Axios.get, showInvoiceMenuUri);
		const invoiceFlag = showInvoiceRes?.data?.data?.invoiceMenu;

		yield put(Actions.creators.getUserNavigationSuccess(res.data, invoiceFlag));
		// if the auto reconcilation was true, then popup wont be shown so wont set the value for that.
		if (res?.data?.autoReconciliation !== true) {
			if (localStorage.getItem('transferFundsPopupVisible') === undefined || localStorage.getItem('transferFundsPopupVisible') === null) {
				yield put(payrollAction.creators.showTransferFundPopup(true));
				setLocalvariable('transferFundsPopupVisible', true);
			}
		}
	} catch (err) {
		// yield put(Actions.creators.getUser());
	}
}

export function* sentHrSetupStatus(action) {
	const baseUrl = `${DOMAIN_URL}`;
	try {
		const uri = `${baseUrl}/UserMenuNavigationStatus/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`;
		const res = yield call(Axios.post, uri, action.payload);
		//::MOCK
		yield put(Actions.creators.getUserNavigation());
	} catch (err) {}
}

export function* getUnEmploymentInsuranceDetailsBannerServices() {
	const baseUrl = `${DOMAIN_URL}`;
	try {
		const uri = `${baseUrl}/UnemploymentInsurance/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/subscribed/count`;
		const res = yield call(Axios.get, uri);
		if (res?.data?.data) {
			yield put(Actions.creators.getUnEmploymentInsuranceDetailsBannerSuccess(res.data));
			localStorage.setItem('showUnemploymentBanner', res?.data?.data?.displayBanner);
		} else {
			localStorage.setItem('showUnemploymentBanner', false);
		}
	} catch (err) {
		yield put(Actions.creators.getUnEmploymentInsuranceDetailsBannerFail());
		localStorage.setItem('showUnemploymentBanner', false);
	}
}
export function* setupStatusServices() {
	const baseUrl = `${DOMAIN_URL}`;
	try {
		const uri = `${baseUrl}/SetupStatus/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getSetupStatusSucess(res.data));
		yield put(Actions.creators.redirectToSetup(res.data && res.data.state ? 'dontRedirectToSetup' : 'redirectToSetup'));
	} catch (err) {
		yield put(Actions.creators.getSetupStatusFail(err));
	}
}
export function* pendingStatusServices() {
	try {
		const uri = `${DOMAIN_URL}/Employees/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/dashboard/pendingrequestcount`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getPendingStatusSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getPendingStatusFail(err));
	}
}
export function* getCorporateListToShowInvoiceServices() {
	try {
		const uri = `${DOMAIN_URL}/Invoice/corporate/enable`;
		const res = yield call(Axios.get, uri);
		const corporateList = res?.data.map((corporate) => corporate?.corporateId);
		yield put(Actions.creators.getCorporateListToShowInvoiceMenuSuccess(corporateList));
	} catch (err) {
		yield put(Actions.creators.getCorporateListToShowInvoiceMenuFail());
	}
}
export function* setupCountServices() {
	const missing = [];
	const completed = [];
	const noData = [];
	const setupCount = (value) => {
		switch (value) {
			case 'Missing info':
				missing.push(value);
				break;
			case 'Completed':
				completed.push(value);
				break;
			case 'No Data':
				noData.push(value);
				break;
			default:
				break;
		}
	};
	try {
		const uri = `${DOMAIN_URL}/CorporateSetup/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/company-setting-status`;
		const res = yield call(Axios.get, uri);
		const uri1 = `${DOMAIN_URL}/CorporateSetup/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/employee-payroll-status`;
		const res1 = yield call(Axios.get, uri1);
		const uri2 = `${DOMAIN_URL}/CorporateSetup/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/document-status`;
		const res2 = yield call(Axios.get, uri2);
		const uri3 = `${DOMAIN_URL}/CorporateSetup/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/leave-workflow-status`;
		const res3 = yield call(Axios.get, uri3);
		setupCount(res?.data);
		setupCount(res1?.data);
		setupCount(res2?.data);
		setupCount(res3?.data);
		const response = {
			total: 4,
			completed: completed?.length,
			missing: missing?.length,
			noData: noData?.length,
		};
		yield put(Actions.creators.getSetupCountSuccess(response));
	} catch (err) {
		yield put(Actions.creators.getSetupCountFail(err));
	}
}
export function* getRakDetailsServices() {
	try {
		const uri = `${DOMAIN_URL}/Corporates/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/transfer-code`;
		const res = yield call(Axios.get, uri);
		// fetch only the non rak bank user
		const fetchTransferCode = res.data?.filter((item) => item?.active && item?.nonRakBank);
		// have to get the last recent data
		const getRecentCode = fetchTransferCode[fetchTransferCode?.length - 1];
		yield put(Actions.creators.getRakBankDetailsSuccess(res.data, getRecentCode?.accountNumber));
	} catch (err) {
		yield put(Actions.creators.getRakBankDetailsFail(err));
	}
}

export function* getProfileImageServices(action) {
	try {
		const uri = `${DOMAIN_URL}/Download/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/download-files?blobName=${
			action.imgPath
		}&blobType=UserProfileImage`;
		const res = yield call(Axios.get, uri, {
			responseType: 'blob',
		});
		const image = res.data.size > 0 ? URL.createObjectURL(res?.data) : [];
		yield put(Actions.creators.getProfileImageSuccess(image));
	} catch (err) {
		yield put(Actions.creators.getProfileImageFail(err));
	}
}

// Its a common api to get features flag and its enabled or not
export function* getFeaturesFlag() {
	try {
		const getFeaturesUri = `${DOMAIN_URL}/Features`;
		const res = yield call(Axios.get, getFeaturesUri);
		yield put(Actions.creators.setFeaturesFlag(res.data));
	} catch (err) {
		console.error(err, 'get feature flag error');
	}
}

export default function* appWatcher() {
	yield takeEvery(Actions.types.GET_ROLES, rolesWorker);
	yield takeEvery(Actions.types.GET_CORPORATE_ID, corporateIdWorker);
	yield takeEvery(Actions.types.USER_NAVIGATION, userNavigationservice);

	yield takeEvery(Actions.types.GET_UNEMPLOYMENT_INSURANCE_BANNER, getUnEmploymentInsuranceDetailsBannerServices);

	yield takeEvery(Actions.types.GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU, getCorporateListToShowInvoiceServices);

	yield takeEvery(Actions.types.GET_SETUP_STATUS_START, setupStatusServices);
	yield takeEvery(Actions.types.GET_PENDING_STATUS_START, pendingStatusServices);
	yield takeEvery(Actions.types.GET_SETUP_COUNT_START, setupCountServices);
	yield takeEvery(Actions.types.GET_RAKBANK_DETAIL_START, getRakDetailsServices);
	yield takeEvery(Actions.types.GET_PROFILE_IMAGE_START, getProfileImageServices);
	yield takeLatest(Actions.types.POST_HR_SETUP_STATUS, sentHrSetupStatus);
	yield takeEvery(Actions.types.CHECK_FEATURES_FLAG, getFeaturesFlag);
	/** future ref
	 yield takeEvery(Actions.types.GET_CLAIMS, claimsWorker);
	 yield takeEvery(Actions.types.GET_NOTIFICATION_COUNT, getNotificationCountSaga);*/
}

import React, { useEffect, useState } from 'react';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import ETypo from '@pages/DesignSystem/Typo';
import '@pages/DesignSystem/Dragger/edragger.scss';
import AppLoader from '@sharedComponent/app-loader';

const statusVariant = { done: 'done', uploading: 'uploading', error: 'error' };

export default function CustomDragger({
	errorMessage,
	acceptedTypes,
	successMessage,
	loadingText1 = 'Uploading Document',
	loadingText2 = 'This can take a few seconds...',
	idleText1 = 'Drag and Drop',
	idleText2 = 'Or Click Here',
	successIcon = focus_state_icons.NewDesignCheck,
	errorIcon = focus_state_icons.NewDesignErro,
	idleIcon = focus_state_icons.hrUpload,
	uploadIcon = focus_state_icons.NewDesignHrUploading,
	uploadDeleteIcon = focus_state_icons.NewDelete,
	onChange,
	onRemoveUpload,
	className = '',
	draggerStatus = 'new',
	fileFormat = '',
	fileMaxSize = '',
	refValue = () => {},
	fileDisabled = false,
	loading = false,
}) {
	const [status, setStatus] = useState(draggerStatus);
	const [fileInfo, setFilesInfo] = useState(draggerStatus);
	const getBordersAndIcons = (mode) => {
		if (status === statusVariant.done) {
			return mode === 'border' ? '2px dashed #9A9EA6' : successIcon;
		} else if (status === statusVariant.uploading) {
			return mode === 'border' ? '2px dashed #DFE1E6' : uploadIcon;
		} else if (status === statusVariant.error) {
			return mode === 'border' ? '2px dashed #FF9691' : errorIcon;
		} else {
			return mode === 'border' ? '2px dashed #eff1f5' : idleIcon;
		}
	};
	useEffect(() => {
		setStatus(draggerStatus);
	}, [draggerStatus]);

	const onUpload = (info) => {
		setStatus('uploading');
		if (info) {
			setStatus('done');
			setFilesInfo(info);
			onChange && onChange(info);
		} else {
			setStatus('new');
			setFilesInfo('new');
		}
	};
	const onRemove = () => {
		document.getElementById('filesystem').value = '';
		setStatus('new');
		setFilesInfo('new');
		onRemoveUpload();
	};
	const textComponent = (text) => {
		return (
			<div className="dragger_hint">
				<EdenredTypo className="dragger_text">{text === 'title' ? idleText1 : loadingText1}</EdenredTypo>
				<EdenredTypo className="dragger_text">{text === 'title' ? idleText2 : loadingText2}</EdenredTypo>
				{fileFormat && (
					<ETypo light b2 style={{ textAlign: 'center' }}>
						{fileFormat}
					</ETypo>
				)}
				{fileMaxSize && (
					<ETypo light b2 style={{ textAlign: 'center' }}>
						{fileMaxSize}
					</ETypo>
				)}
			</div>
		);
	};

	return (
		<div className={`main-container-dragger ${className}`}>
			{loading ? (
				<AppLoader />
			) : (
				<div className="dragger">
					<div
						className="dragger-container"
						style={{
							border: getBordersAndIcons('border'),
						}}>
						{idleIcon === focus_state_icons?.loaderIcon ? (
							<div className="imageuploadIndicator ">
								<div className="loader-border">
									<ImageComponent src={idleIcon} className={`loader `}></ImageComponent>
								</div>
							</div>
						) : (
							<div className="imageuploadIndicator">
								<ImageComponent src={idleIcon}></ImageComponent>
							</div>
						)}
						{status === 'new' ? (
							textComponent('title')
						) : status === 'uploading' ? (
							textComponent('upload')
						) : status === 'done' ? (
							<div className="dragger_hint">
								<EdenredTypo className="dragger_text_2">
									{successMessage || fileInfo?.name}
									<ImageComponent src={uploadDeleteIcon} onClick={onRemove} className="deleteIcon"></ImageComponent>
								</EdenredTypo>

								{fileInfo && fileInfo?.size && (
									<EdenredTypo className="dragger_subtext">{(fileInfo?.size / 1024 / 1024).toFixed(2)} MB</EdenredTypo>
								)}
							</div>
						) : (
							<div className="dragger_hint">
								<EdenredTypo className="dragger_text_2">
									{errorMessage || fileInfo?.name}
									<ImageComponent src={uploadDeleteIcon} onClick={onRemove} className="deleteIcon"></ImageComponent>
								</EdenredTypo>
								<EdenredTypo className="dragger_error_text">{fileInfo?.error?.message}</EdenredTypo>
							</div>
						)}
					</div>
					{!fileDisabled && (
						<input
							ref={refValue}
							type="file"
							id="filesystem"
							accept={acceptedTypes ? acceptedTypes : '.xls, .xlsx'}
							disabled={fileDisabled}
							className="file-input"
							onChange={(e) => onUpload(e.target.files[0])}></input>
					)}
				</div>
			)}
		</div>
	);
}

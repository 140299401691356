import React, { useState, useEffect } from 'react';
import { Row, Col, message as AntdMessage } from 'antd';
import { DotLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import EdenredSearch from '@sharedComponent/search-input';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { entriesDropDown } from '@helpers';
import Table from '@pages/DesignSystem/Table/Table';
import EmployeeService from 'src/services/employee-service';
import ClevertapReact from 'src/utils/clever-tap';
import { icons } from 'src/assets/icons';
import Toast from '@pages/DesignSystem/Toast';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { SITEMAP } from 'src/routes/sitemap';
import useCheckNewEstablishmentId from '@pages/employees/manage-employees/hooks/useCheckNewEstablishmentId';

const MultipleEmployeesSubmit = ({ employeesList = [], isSetup = false, status = '', nextRoute = '', handleInfiniteScroll = () => {} }) => {
	const { state } = useLocation() || {};
	const [submitModal, setSubmitModal] = useState(false);
	const [loading, setloading] = useState(false);
	const [message, setMessage] = useState('');
	const [showtoast, setShowToast] = useState(false);
	let [userData, setUserData] = useState([]);
	let [pageSize, setPageSize] = useState(10);
	let [pageNumber, setPageNumber] = useState(1);
	const [addEmployeeList, setAddEmployeeList] = useState([]);
	const [searchvalue, setSearchValue] = useState('');

	let employeeApi = new EmployeeService();
	let history = useHistory();
	let file;
	let data;
	const isBulkUpdate = state?.status === 'update';

	if (state && state?.files && state?.files?.file) {
		file = state?.files?.file?.originFileObj;
		data = state?.data;
	}
	const setUpList = isSetup ? employeesList : userData;

	const { checkEstablishmentId, goToTradeLicensePage, establishmentEmployees, setEstablishmentIdData, clearEstablishmentId } =
		useCheckNewEstablishmentId(); // custom hook for checking new establishment on employees

	useEffect(() => {
		if (establishmentEmployees?.isCalled) {
			checkingEstablishmentIdFlow();
		}
	}, [establishmentEmployees]);

	useEffect(() => {
		if (state?.data && Array.isArray(state?.data)) {
			setUserData(state.data);
		}
	}, [state]);

	useEffect(() => {
		if (searchvalue?.length > 0) {
			const a = setUpList?.filter((data) => {
				return (
					data?.employeeId?.toString()?.toLowerCase()?.indexOf(searchvalue?.toLowerCase()) >= 0 ||
					data?.name?.toString()?.toLowerCase()?.indexOf(searchvalue?.toLowerCase()) >= 0
				);
			});

			setAddEmployeeList(a);
			if (pageNumber && pageSize) {
				var start = (pageNumber - 1) * pageSize;
				var end = start + pageSize;
				let f = a.slice(start, end);
				setAddEmployeeList(f);
			}
		} else {
			let a = setUpList;
			var start = (pageNumber - 1) * pageSize;
			var end = start + pageSize;
			let f = a?.slice(start, end);
			if (f.length == 0) {
				setPageNumber(1);
			}

			setAddEmployeeList(f);
		}
	}, [searchvalue, pageNumber, pageSize, userData]);

	const showModal = () => {
		isSetup && ClevertapReact.event('Submit_EmployeePayroll');
		setSubmitModal(true);
	};

	const closeModal = () => setSubmitModal(false);

	let currentUser = useSelector((state) => state?.sharedStates?.currentUser?.user?.userName);

	const handleBulkData = async (isBulkUpdate = false, userData, currentUser, handleSuccess = () => {}) => {
		const addBulkData = async () => {
			ClevertapReact.event('Submit_BulkEmployees', {
				Number_of_employees: userData?.length || null,
				Salary_channel: userData?.salaryChannel || null,
				Submitted_time: new Date().toISOString(),
			});
			const res = await employeeApi.createEmployees(userData);
			if (res) {
				handleSuccess('The employee had been created Successfully');
			}
		};

		const updateBulkData = async () => {
			const res = await employeeApi.createEmployeesNew(currentUser);
			if (res) {
				handleSuccess('The Employee have been updated');
			}
		};

		if (isBulkUpdate) {
			await updateBulkData();
		} else {
			await addBulkData();
		}
	};

	const bulkUploadNextSteps = () => {
		try {
			const handleSuccess = (message) => {
				setSubmitModal(false);
				setMessage(message);
				setShowToast(true);
			};

			handleBulkData(isBulkUpdate, userData, currentUser, handleSuccess);
		} catch (error) {
			setloading(false);
		}
	};

	const checkingEstablishmentIdFlow = () => {
		try {
			const data = {
				nextSteps: () => {},
			};

			const handleSuccess = (message) => {
				setSubmitModal(false);
				setMessage(message);
				setShowToast(true);
			};

			if (establishmentEmployees?.establishmentIds && establishmentEmployees.isFound) {
				data.nextSteps = async () => await handleBulkData(isBulkUpdate, userData, currentUser, handleSuccess);
				setEstablishmentIdData(data);
				if (isBulkUpdate) {
					goToTradeLicensePage('update-bulk');
				} else {
					goToTradeLicensePage('add-bulk', { noOfEmployees: userData.length });
				}
			} else if (establishmentEmployees.error) {
				AntdMessage.error(String(establishmentEmployees.errorData));
				clearEstablishmentId();
				setloading(false);
			} else {
				clearEstablishmentId();
				handleBulkData(isBulkUpdate, userData, currentUser, handleSuccess);
			}
		} catch (error) {
			AntdMessage.error(String(error));
			console.error(error);
			setloading(false);
		}
	};

	async function handleSubmit() {
		setloading(true);
		const wpsEstIdKey = isBulkUpdate ? 'wpsEstablishmentID' : 'wpsEstablishmentId';
		// checking any one employee having wps est id, if exits we are checking est id check
		const isWpsEstIdExists = setUpList?.some((emp) => emp[wpsEstIdKey]);
		if (isWpsEstIdExists) {
			const wpsEstablishmentIDs = setUpList?.map((emp) => emp[wpsEstIdKey]).filter((emp) => emp) ?? [];
			checkEstablishmentId(wpsEstablishmentIDs);
		} else {
			bulkUploadNextSteps();
		}
	}

	const getEmployeeName = (data) => {
		if (data && data.employeeName && typeof data.employeeName) {
			return data.employeeName;
		} else if (data && data.firstName && typeof data.firstName) {
			return data.firstName;
		} else {
			return '';
		}
	};

	const column = [
		{
			key: 'employeeName',
			title: 'Name',
			width: '38%',
			dataIndex: 'employeeName',
			render: (employeeName, rowData) => {
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<ETypo b2 light color="#181919">
							{getEmployeeName(rowData)}
						</ETypo>
					</div>
				);
			},
		},

		{
			key: 'employeeId',
			title: 'Employee ID',
			dataIndex: 'employeeId',
			width: '38%',
			// align: 'left',
			render: (employeeId) => {
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<ETypo b2 light color="#181919">
							{employeeId}
						</ETypo>
					</div>
				);
			},
		},
	];

	const closeToast = () => {
		setShowToast(false);
		if (isBulkUpdate) {
			history.push({
				pathname: isSetup ? nextRoute : SITEMAP.employees.manage,
				state: { noOfEmployees: userData.length },
			});
		} else {
			history.replace({ pathname: SITEMAP.employees.employeeNextSteps, state: { noOfEmployees: userData?.length } });
		}
		setloading(false);
	};

	const onCancel = () => {
		if (isBulkUpdate) {
			history.push(SITEMAP.employees.updateBulk);
		} else {
			history.push(SITEMAP.employees.addBulk);
		}
	};

	return (
		<>
			<Toast visible={showtoast} clearPopUp={closeToast} color={'#8EF9B3'} left="40%">
				{message}
			</Toast>
			<Row className="bulk-employee-detail-container">
				<Col xl={24} md={24} lg={24}>
					<div className="bulk-employee-header-container">
						<ETypo h2 bold className="bulk-employee-page-title">
							You’re {isBulkUpdate ? 'updating' : 'adding'} the following employees.
						</ETypo>
						<div className="bulk-employee-button-container">
							<EdenredButtons
								leftIcon={<ImageComponent src={focus_state_icons.InviteCancelButton} />}
								btnType="secondary"
								onClick={onCancel}>
								Cancel
							</EdenredButtons>
							<EdenredButtons loading={loading} disabled={setUpList?.length === 0} onClick={showModal} btnType="primary">
								Submit
							</EdenredButtons>
						</div>
					</div>
					<div className="bulk-employee-sub-header">
						<ETypo b2 light color="#484B52">
							Click "submit" to proceed
						</ETypo>
					</div>
					<div className="bulk-employee-info-board">
						<div className="bulk-employee-search-and-show-count-container">
							<EdenredSearch
								value={searchvalue}
								placeholder="Search by Name, Employee ID..."
								onChange={(event) => setSearchValue(event.target.value)}
							/>
							<div className="bulk-employee-entries-select-container">
								<ETypo b2 light color="#484B52">
									Show
								</ETypo>
								<MultiSelect
									{...{
										Option: entriesDropDown,
										value: pageSize,
										dropDownValue: 'value',
										searchVisibility: false,
										onChange: (page) => setPageSize(page.value),
										checkboxIcon: (
											<span className="entries_check_box">
												<img src={icons.not_focus.tick} alt="check" />
											</span>
										),
									}}
								/>
								<ETypo b2 light color="#484B52">
									Entries
								</ETypo>
							</div>
						</div>

						<div className="bulk-employee-table-card-container">
							{1 < 0 ? (
								<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
									<DotLoader size={50} color={'#e91d24'} loading />
								</Col>
							) : (
								<Table data={addEmployeeList} columns={column} />
							)}
						</div>

						<div className="bulk-employee-card-pagination-container">
							<ETypo b3 light color="#484B52">
								Showing {addEmployeeList.length} out of {setUpList.length} Employees
							</ETypo>
							<Pagination
								{...{
									currentPage: pageNumber,
									totalCount: setUpList.length,
									pageSize: pageSize,
									onPageChange: (page) => setPageNumber(page),
								}}
							/>
						</div>
						<Edenredmodal
							title={`Click on “Submit” to confirm`}
							onOkay={handleSubmit}
							onCancelButton="Cancel"
							onOkayButton="Submit"
							onCancel={closeModal}
							isOpen={submitModal}
							loading={loading}
							close={closeModal}
						/>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default MultipleEmployeesSubmit;

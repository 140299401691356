import Actions from './action';
export const initState = {
	roles: [],
	rolesLoading: false,
	claims: {
		User: [],
		Verifier: [],
	},
	claimsLoading: false,
	currentUser: {
		user: null,
	},
	currentUserDetailsOIDC: {
		user: null,
		checkedForUser: false,
	},
	corporateId: null,
	corporateIds: [],
	corporateLoading: false,
	corporateAPIStatus: null,
	subscriptionProds: [],
	userNavigationLoad: false,
	userNavigation: null,
	setupStatus: 'loading',
	setupStatusDetail: {},
	redirectStatus: '',
	pendingStatusLoading: false,
	PendingStatusDetail: {},
	pendingStatusFail: '',
	isOnlyEmployeeExits: false,
	setupCountloading: false,
	setupCountDetail: {},
	isRakbankLoading: false,
	rakbankdetails: [],
	transferCode: null,
	isUserEmploymentClosed: false,
	profileImg: null,
	profileImgLoading: false,

	getInvoiceInfoLoading: false,
	invoiceDetails: null,

	unEmploymentBannerLoading: false,
	unEmploymentBannerData: null,
	showInvoiceMenuFlag: false,

	invoiceCorporateList: [],
	invoiceCorporateListLoading: false,

	showEwaPopup: false,

	features: null,
};

const appReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_ROLES: {
			return {
				...state,
				rolesLoading: true,
			};
		}
		case Actions.types.GET_ROLES_SUCCESS: {
			return {
				...state,
				roles: action.response,
				rolesLoading: false,
			};
		}
		case Actions.types.GET_ROLES_FAILED: {
			return {
				...state,
				rolesLoading: false,
			};
		}
		case Actions.types.GET_PROFILE_IMAGE_START: {
			return {
				...state,
				profileImgLoading: true,
			};
		}
		case Actions.types.GET_PROFILE_IMAGE_SUCCESS: {
			return {
				...state,
				profileImg: action.img,
				profileImgLoading: false,
			};
		}
		case Actions.types.GET_PROFILE_IMAGE_FAIL: {
			return {
				...state,
				profileImgLoading: false,
			};
		}
		case Actions.types.GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU: {
			return {
				...state,
				invoiceCorporateList: [],
				invoiceCorporateListLoading: true,
			};
		}
		case Actions.types.GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU_SUCCESS: {
			return {
				...state,
				invoiceCorporateList: action.res,
				invoiceCorporateListLoading: false,
			};
		}
		case Actions.types.GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU_FAIL: {
			return {
				...state,
				invoiceCorporateList: [],
				invoiceCorporateListLoading: false,
			};
		}
		case Actions.types.GET_CORPORATE_ID: {
			return {
				...state,
				corporateLoading: true,
			};
		}
		case Actions.types.GET_CORPORATE_ID_SUCCESS: {
			return {
				...state,
				corporateLoading: false,
				corporateId: action.corporateId,
				corporateIds: action.corporateIds,
				corporateAPIStatus: true,
			};
		}
		case Actions.types.GET_CORPORATE_ID_FAILED: {
			return {
				...state,
				corporateLoading: false,
				corporateAPIStatus: false,
				currentUserDetailsOIDC: {
					...state.currentUserDetailsOIDC,
					checkedForUser: true,
				},
			};
		}
		case Actions.types.GET_CLAIMS_SUCCESS: {
			return {
				...state,
				claims: {
					...state.claims,
					[action.roleType]: action.response,
				},
			};
		}
		case Actions.types.SUBSCRIPTION_SUCCESS: {
			return {
				...state,
				subscriptionProds: action.response,
			};
		}
		case Actions.types.SET_USER: {
			return {
				...state,
				currentUser: action.response,
			};
		}
		case Actions.types.SET_USER_DETAILS: {
			return {
				...state,
				currentUserDetailsOIDC: action.response,
			};
		}
		case Actions.types.REMOVE_USER: {
			return {
				...state,
				currentUser: null,
			};
		}
		case Actions.types.GET_UNEMPLOYMENT_INSURANCE_BANNER: {
			return {
				...state,
				unEmploymentBannerLoading: true,
				unEmploymentBannerData: null,
			};
		}
		case Actions.types.GET_UNEMPLOYMENT_INSURANCE_BANNER_SUCCESS: {
			return {
				...state,
				unEmploymentBannerLoading: false,
				unEmploymentBannerData: action.res,
			};
		}
		case Actions.types.GET_UNEMPLOYMENT_INSURANCE_BANNER_FAIL: {
			return {
				...state,
				unEmploymentBannerLoading: false,
				unEmploymentBannerData: null,
			};
		}
		case Actions.types.USER_NAVIGATION: {
			return {
				...state,
				userNavigationLoad: true,
			};
		}
		case Actions.types.USER_NAVIGATION_SUCCUESS: {
			return {
				...state,
				userNavigationLoad: false,
				userNavigation: action.response,
				showInvoiceMenuFlag: action.invoiceFlag,
				showEwaPopup: action?.response?.ewaPopupDone ? false : true,
			};
		}
		case Actions.types.SET_SHOW_EWA_POPUP: {
			return {
				...state,
				showEwaPopup: action.flag,
			};
		}
		case Actions.types.USER_NAVIGATION_FAIL: {
			return {
				...state,
				userNavigationLoad: false,
				userNavigation: null,
				showInvoiceMenuFlag: false,
			};
		}
		case Actions.types.GET_SETUP_STATUS_START: {
			return {
				...state,
				setupStatus: 'loading',
			};
		}
		case Actions.types.GET_SETUP_STATUS_SUCCESS: {
			return {
				...state,
				setupStatus: action?.res && action?.res?.state ? 'completed' : 'notCompleted',
				setupStatusDetail: action?.res,
			};
		}
		case Actions.types.GET_SETUP_STATUS_FAIL: {
			return {
				...state,
				setupStatus: 'failed',
			};
		}
		case Actions.types.REDIRECT_TO_SETUP: {
			return {
				...state,
				redirectStatus: action.flag,
			};
		}
		case Actions.types.GET_PENDING_STATUS_START: {
			return {
				...state,
				pendingStatusLoading: true,
			};
		}
		case Actions.types.GET_PENDING_STATUS_SUCCESS: {
			return {
				...state,
				pendingStatusLoading: false,
				PendingStatusDetail: action?.res,
			};
		}
		case Actions.types.GET_PENDING_STATUS_FAIL: {
			return {
				...state,
				pendingStatusLoading: false,
				PendingStatusDetail: {},
				pendingStatusFail: action?.err,
			};
		}
		case Actions.types.IS_ONLY_EMPLOYEE_EXISTS: {
			return {
				...state,
				isOnlyEmployeeExits: action.flag,
			};
		}
		case Actions.types.GET_SETUP_COUNT_START: {
			return {
				...state,
				setupCountloading: true,
			};
		}
		case Actions.types.GET_SETUP_COUNT_SUCCESS: {
			return {
				...state,
				setupCountloading: false,
				setupCountDetail: action.res,
			};
		}
		case Actions.types.GET_SETUP_COUNT_FAIL: {
			return {
				...state,
				setupCountloading: false,
				setupCountDetail: {},
			};
		}
		case Actions.types.GET_RAKBANK_DETAIL_START: {
			return {
				...state,
				isRakbankLoading: true,
			};
		}
		case Actions.types.GET_RAKBANK_DETAIL_SUCCESS: {
			return {
				...state,
				isRakbankLoading: false,
				rakbankdetails: action.res,
				transferCode: action.transferCode,
			};
		}
		case Actions.types.GET_RAKBANK_DETAIL_FAIL: {
			return {
				...state,
				isRakbankLoading: false,
				rakbankdetails: action.error,
			};
		}
		case Actions.types.SET_HR_SETUP_STATUS: {
			const { payload } = action;
			return {
				...state,
				userNavigation: {
					...state.userNavigation,
					...payload,
				},
			};
		}
		/**
		 * To get invoice details
		 */
		case Actions.types.GET_INVOICE_INFO_START: {
			return {
				...state,
				getInvoiceInfoLoading: true,
				invoiceDetails: null,
			};
		}

		case Actions.types.GET_INVOICE_INFO_SUCCESS: {
			return {
				...state,
				getInvoiceInfoLoading: false,
				invoiceDetails: action.data,
			};
		}
		case Actions.types.GET_INVOICE_INFO_FAIL: {
			return {
				...state,
				getInvoiceInfoLoading: false,
				invoiceDetails: null,
			};
		}
		case Actions.types.SET_FEATURES_FLAG: {
			return {
				...state,
				features: action.payload,
			};
		}
		default:
			return state;
	}
};

export default appReducer;

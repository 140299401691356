import { createSelector } from 'reselect';
import { initState } from './reducer';

const EmployeesReducer = (state) => state.manageEmployees || initState;

const isEmailDataLoading = createSelector(EmployeesReducer, (cnter) => cnter.isEmailDataLoading);
const emailDataSave = createSelector(EmployeesReducer, (cnter) => cnter.emailDataSave);
const isEmailUpdateLoad = createSelector(EmployeesReducer, (cnter) => cnter.isEmailUpdateLoad);
const emailUpdated = createSelector(EmployeesReducer, (cnter) => cnter.emailUpdated);
const isInvitePortalPopup = createSelector(EmployeesReducer, (cnter) => cnter.isInvitePortalPopup);
const newEmployeeData = createSelector(EmployeesReducer, (cnter) => cnter.newEmployeeData);
const isEmployeeNewDataLoading = createSelector(EmployeesReducer, (cnter) => cnter.isEmployeeNewDataLoading);
const inviteCardStatus = createSelector(EmployeesReducer, (cnter) => cnter.inviteCardStatus);

export default {
	EmployeesReducer,
	isEmailDataLoading,
	emailDataSave,
	isEmailUpdateLoad,
	emailUpdated,
	isInvitePortalPopup,
	newEmployeeData,
	isEmployeeNewDataLoading,
	inviteCardStatus,
};

import React, { useState } from 'react';
import { icons } from '@assets/icons';
import ETypo from '@pages/DesignSystem/Typo';
import './banner.scss';

const TradeLicenseBanner = () => {
	const [isBannerVisible, setisBannerVisible] = useState(true);
	if (isBannerVisible)
		return (
			<div className="sort-alert-info-box error trade-license-banner">
				<div className="info-icon">
					<img src={icons.focus_state.tickHexagonPlain} alt="hexagon-plain-tick" />
				</div>
				<div className="info-content">
					<ETypo h5 bold>
						Trade License Verification in Progress
					</ETypo>
					<br />
					<ETypo b1>
						Your trade license verification with the establishment ID is underway. You will receive an email notification once it is
						<br /> successfully verified, within the next 48 hours.
					</ETypo>
				</div>
				<div className="info-action" onClick={() => setisBannerVisible(false)}>
					<ETypo color="#181919" b2 medium className="info-action-link">
						Understood
					</ETypo>
				</div>
			</div>
		);
	return null;
};

export default TradeLicenseBanner;
